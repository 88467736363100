import axios from 'axios';
import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import {getAPIBaseUrl} from '../utils';

const objComercio = {
    comercioId: '',
    comercioNitEdit: '',
    comercioNombreEdit: '',
    comercioDireccionEdit: '',
    comercioTelefonoEdit: '',
    comercioEmailEdit: ''
};

const objUsuario = {
    usuarioId: '',
    comercioId: '',
    usuarioTipoDocumento: '-',
    usuarioDocumentoEdit: '',
    usuarioNombresEdit: '',
    usuarioApellidosEdit: '',
    usuarioDireccionEdit: '',
    usuarioCorreoElectronicoEdit: '',
    usuarioCelularEdit: '',
    usuarioContraseniaEdit: '',
    usuarioRol: 'admincom'
};

const ModalEditarComercio = ({ comercioId, refresh='' }) => {
    // const lastInsertComercioIdRef = useRef('');
    const [comercio, setComercio] = useState(objComercio);
    const [usuario, setUsuario] = useState(objUsuario);

    useEffect(() => {
        const getComercioData = async () => {
            // setLoading(true);

            setComercio(objComercio);
            setUsuario(objUsuario);

            // console.log('comercioId:', comercioId);

            if(!comercioId) { return; }

            try {
                const responseCom = await axios.get( getAPIBaseUrl()+`comercios/byid/${comercioId}`);
                const comercioData = responseCom.data.message[0];
                // console.log('comercio data:', comercioData);
                
                if(comercioData.comercio_id !== '') {
                    setComercio({
                        comercioId: comercioData.comercio_id,
                        comercioNitEdit: comercioData.comercio_nit,
                        comercioNombreEdit: comercioData.comercio_nombre,
                        comercioDireccionEdit: comercioData.comercio_direccion,
                        comercioTelefonoEdit: comercioData.comercio_telefono,
                        comercioEmailEdit: comercioData.comercio_email
                    });

                    const responseUsu = await axios.get( getAPIBaseUrl()+`usuarios/admbycomercioid/${comercioId}`);
                    // console.log('responseUsu:', responseUsu.data.success);
                    if(responseUsu.data.success) {
                        const usuarioData = responseUsu.data.message[0];
                        // console.log('usuarioData:', usuarioData);
                    
                        setUsuario({
                            usuarioId: usuarioData.usuario_id,
                            comercioId: comercioId,
                            usuarioTipoDocumento: '-',
                            usuarioDocumentoEdit: usuarioData.usuario_documento,
                            usuarioNombresEdit: usuarioData.usuario_nombres,
                            usuarioApellidosEdit: usuarioData.usuario_apellidos,
                            usuarioDireccionEdit: usuarioData.usuario_direccion,
                            usuarioCorreoElectronicoEdit: usuarioData.usuario_correo_electronico,
                            usuarioCelularEdit: usuarioData.usuario_celular,
                            usuarioContraseniaEdit: '',
                            usuarioRol: 'admincom'
                        });
                    } else {
                        setUsuario({
                            usuarioId: '',
                            comercioId: comercioId,
                            usuarioTipoDocumento: '-',
                            usuarioDocumentoEdit: '',
                            usuarioNombresEdit: '',
                            usuarioApellidosEdit: '',
                            usuarioDireccionEdit: '',
                            usuarioCorreoElectronicoEdit: '',
                            usuarioCelularEdit: '',
                            usuarioContraseniaEdit: '',
                            usuarioRol: 'admincom'
                        });
                    }

                    // setLoading(false);
                }

            } catch (error) {
                // console.log('error:', error);

                if(error.code !== 'ECONNABORTED') {
                    console.error( 'Error al traer los datos de los clientes', error );
                }
            }
        }

        getComercioData();
    }, [comercioId]);

    const handleChangeComercio = (e) => {
        setComercio({
            ...comercio,
            [e.target.name]: e.target.value
        });
    }

    const handleChangeUsuario = (e) => {
        setUsuario({
            ...usuario,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmitEditarComercio = async (evt) => {
        evt.preventDefault();

        // console.log('Editar comercio save:', comercio);
        // console.log('Editar usuario save:', usuario);

        try {
            const response = await axios.post( getAPIBaseUrl()+`comercios/modificar`, comercio );
            // console.log( 'response comercio:', response.data);
            if(!response.data.success) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error comercio',
                    text: response.data.message,
                    // footer: '<a href="">Why do I have this issue?</a>'
                });
                return;
            }

        } catch (error) {
            // console.error( 'Error creando comercio', error );
            Swal.fire({
                icon: 'error',
                title: 'Error interno comercio',
                text: error,
                // footer: '<a href="">Why do I have this issue?</a>'
            });
            return;
        }

        const rutaUsr = usuario.usuarioId === '' ? 'usuarios/nuevo' : 'usuarios/modificar';
        // console.log('rutaUsr:', rutaUsr);


        // Crear/Editar usuario admin del comercio
        try {
            const response = await axios.post( getAPIBaseUrl()+rutaUsr, usuario);
            // console.log( 'response usuario:', response.data);
            
            if(!response.data.success) {
                // console.log('comercioId:', formDataUsuario.comercioId);
                // console.log('comercioIdRef:', lastInsertComercioIdRef.current);
                // await axios.post(`${getAPIBaseUrl()}comercios/eliminar`, {id: usuario.comercioId});

                Swal.fire({
                    icon: 'error',
                    title: 'Error usuario',
                    text: response.data.message,
                    // footer: '<a href="">Why do I have this issue?</a>'
                });
                return;

            } else {
                Swal.fire({
                    title: 'Comercio Editado!',
                    text: 'Correctamente!',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    // showCancelButton: true,
                    confirmButtonClass: 'btn btn-success',
                    // cancelButtonClass: 'btn btn-danger'
                }).then((result) => {
                    // console.log('result:', result);
                    if(result.isConfirmed) {
                        setComercio(objComercio);
                        setUsuario(objUsuario);

                        const cerrar = document.querySelector('#modalEditarComercio .close');
                        cerrar.click();
                    }
                });
            }

        } catch (error) {
            // console.log('comercioId:', usuario.comercioId);
            // console.log('comercioIdRef:', lastInsertComercioIdRef.current);
            // await axios.post(`${getAPIBaseUrl()}comercios/eliminar`, {id: usuario.comercioId});

            // console.error( 'Error creando usuario', error );
            Swal.fire({
                icon: 'error',
                title: 'Error interno usuario',
                text: error,
                // footer: '<a href="">Why do I have this issue?</a>'
            });
            return;
        }
    }

    return (
        <form onSubmit={ handleSubmitEditarComercio }>
            <div
                className="modal fade bs-example-modal-lg"
                id="modalEditarComercio"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabelComercio"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myLargeModalLabelComercio">
                                Editar Comercio
                            </h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-hidden="true"
                            >
                                ×
                            </button>
                        </div>

                        <div className="modal-body">
                            
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="comercioNitEdit">Nit</label>
                                        <input
                                            className="form-control"
                                            id="comercioNitEdit"
                                            name="comercioNitEdit"
                                            type="number" 
                                            placeholder="Nit"
                                            value={comercio.comercioNitEdit}
                                            onChange={ handleChangeComercio }
                                            required 
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="comercioNombreEdit">Razon Social</label>
                                        <input
                                            className="form-control"
                                            id="comercioNombreEdit"
                                            name="comercioNombreEdit"
                                            type="text"
                                            placeholder="Nombre"
                                            value={ comercio.comercioNombreEdit }
                                            onChange={ handleChangeComercio }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="comercioDireccionEdit">Dirección</label>
                                        <input
                                            className="form-control"
                                            id="comercioDireccionEdit"
                                            name="comercioDireccionEdit"
                                            type="text"
                                            placeholder="Dirección"
                                            value={ comercio.comercioDireccionEdit }
                                            onChange={ handleChangeComercio }
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="comercioTelefonoEdit">Teléfono</label>
                                        <input
                                            className="form-control"
                                            id="comercioTelefonoEdit"
                                            name="comercioTelefonoEdit"
                                            type="number"
                                            placeholder="Teléfono"
                                            value={ comercio.comercioTelefonoEdit }
                                            onChange={ handleChangeComercio }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="comercioEmailEdit">Email</label>
                                        <input
                                            className="form-control" 
                                            id="comercioEmailEdit"
                                            name="comercioEmailEdit"
                                            type="email"
                                            placeholder="Email"
                                            value={ comercio.comercioEmailEdit }
                                            onChange={ handleChangeComercio }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <hr />

                            {/* Datos del usuario */}

                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                <h4 className="modal-title" id="myLargeModalLabelComercio">
                                    Datos Usuario
                                </h4>
                                </div>
                            </div>

                            <hr /><br />

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="usuarioNombresEdit">Nombre</label>
                                        <input
                                            className="form-control"
                                            id="usuarioNombresEdit"
                                            name="usuarioNombresEdit"
                                            type="text"
                                            placeholder="Nombre"
                                            value={ usuario.usuarioNombresEdit }
                                            onChange={ handleChangeUsuario }
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="usuarioApellidosEdit">Apellidos</label>
                                        <input
                                            className="form-control" 
                                            id="usuarioApellidosEdit"
                                            name="usuarioApellidosEdit"
                                            type="text"
                                            placeholder="Apellidos"
                                            value={ usuario.usuarioApellidosEdit }
                                            onChange={ handleChangeUsuario }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="usuarioDireccionEdit">Direccion</label>
                                        <input
                                            className="form-control" 
                                            id="usuarioDireccionEdit"
                                            name="usuarioDireccionEdit"
                                            type="text"
                                            placeholder="Direccion"
                                            value={ usuario.usuarioDireccionEdit }
                                            onChange={ handleChangeUsuario }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="usuarioDocumentoEdit">Documento</label>
                                        <input
                                            className="form-control" 
                                            id="usuarioDocumentoEdit"
                                            name="usuarioDocumentoEdit"
                                            type="number"
                                            placeholder="Documento"
                                            value={ usuario.usuarioDocumentoEdit }
                                            onChange={ handleChangeUsuario }
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="usuarioCelularEdit">Celular</label>
                                        <input
                                            className="form-control" 
                                            id="usuarioCelularEdit"
                                            name="usuarioCelularEdit"
                                            type="number"
                                            placeholder="Celular"
                                            value={ usuario.usuarioCelularEdit }
                                            onChange={ handleChangeUsuario }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="usuarioCorreoElectronicoEdit">Email</label>
                                        <input
                                            className="form-control" 
                                            id="usuarioCorreoElectronicoEdit"
                                            name="usuarioCorreoElectronicoEdit"
                                            type="email"
                                            placeholder="Email"
                                            value={ usuario.usuarioCorreoElectronicoEdit }
                                            onChange={ handleChangeUsuario }
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="usuarioContraseniaEdit">Contraseña</label>
                                        <input
                                            className="form-control"
                                            id="usuarioContraseniaEdit"
                                            name="usuarioContraseniaEdit"
                                            type="password"
                                            placeholder="Contraseña"
                                            value={ usuario.usuarioContraseniaEdit }
                                            onChange={ handleChangeUsuario }
                                            autoComplete="new-password"
                                            required={usuario.usuarioId === '' ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Cancelar
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Guardar
                            </button>
                        </div>
                    
                    </div>
                </div>
            </div>
        </form>
    )
}

export default ModalEditarComercio
