import React, {useState,useContext} from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import Preloader from '../Layouts/Preloader';

const Login = () => {
    const {loginUser, wait, loggedInCheck} = useContext(UserContext);
    const [redirect, setRedirect] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [formData, setFormData] = useState({
        email:'',
        password:''
    });

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();

        if(!Object.values(formData).every(val => val.trim() !== '')) {
            setErrMsg('Debe llenar todos los campos, son requeridos!');
            return;
        }

        const data = await loginUser(formData);
        // console.log('data:', data);

        if(data.success) {
            e.target.reset();
            setRedirect('Redirecting...');
            await loggedInCheck();
            return;
        }
        setErrMsg(data.message);
    }

    return (
        <div className="login-page">
            <Preloader/>
            <div className="login-header box-shadow">
                <div
                    className="container-fluid d-flex justify-content-between align-items-center"
                >
                    <div className="brand-logo">
                        <Link to={`/`} className="dropdown-toggle no-arrow">
                            <img
                                src={ process.env.PUBLIC_URL + "/logo-creditxtatts.png" }
                                alt=""
                                className="dark-logo"
                            />
                            <img
                                src={ process.env.PUBLIC_URL + "/logo-creditxtatts.png" }
                                alt=""
                                className="light-logo"
                            />
                        </Link>
                    </div>
                </div>
            </div>

            <div
                className="login-wrap d-flex align-items-center flex-wrap justify-content-center"
            >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-lg-7">
                            {/* <img src="../../theme/vendors/images/login-page-img.png" alt="" /> */}
                            <img 
                                src={ process.env.PUBLIC_URL + "/login-creditxtatts.png" } 
                                alt="login" 
                                style={{'borderRadius':'20px'}}
                            />
                        </div>
                        <div className="col-md-6 col-lg-5">
                            <div className="login-box bg-white box-shadow border-radius-10">
                                <div className="login-title">
                                    <h2 className="text-center text-primary">Ingresar</h2>
                                </div>

                                <form onSubmit={submitForm}>
                                    <div className="input-group custom">
                                        <input
                                            type="text"
                                            className="form-control form-control-lg"
                                            id="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={onChangeInput}
                                            placeholder="Correo"
                                            required
                                        />
                                        <div className="input-group-append custom">
                                            <span className="input-group-text"
                                                ><i className="icon-copy dw dw-user1"></i
                                            ></span>
                                        </div>
                                    </div>

                                    <div className="input-group custom">
                                        <input
                                            type="password"
                                            className="form-control form-control-lg"
                                            id="password"
                                            name="password"
                                            value={formData.password}
                                            onChange={onChangeInput}
                                            placeholder="**********"
                                            required
                                        />
                                        <div className="input-group-append custom">
                                            <span className="input-group-text"
                                                ><i className="dw dw-padlock1"></i
                                            ></span>
                                        </div>
                                    </div>

                                    {/* <div className="row pb-30">
                                        <div className="col-6">
                                            <div className="custom-control custom-checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id="customCheck1"
                                                />
                                                <label className="custom-control-label" htmlFor="customCheck1"
                                                    >Recordar
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="forgot-password">
                                                <a href="forgot-password.html">
                                                    Olvide mi contraseña
                                                </a>
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="row">
                                        <div className="col-sm-12">
                                            { errMsg && <div className="has-danger">{errMsg}</div> }
                                            <br />
                                            <div className="input-group mb-0">
                                                { redirect ?
                                                        redirect
                                                    :
                                                        <button type="submit" className="btn btn-primary btn-lg btn-block" disabled={wait}>
                                                            Ingresar
                                                        </button>
                                                }
                                                
                                                {/* {
                                                    redirect ? redirect :
                                                    <Link to={`/`} className="btn btn-primary btn-lg btn-block" disabled={wait}>
                                                        <span className="mtext">Ingresar</span>
                                                    </Link>
                                                } */}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login