import axios from 'axios';
import {  useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import {convertirACOP, getAPIBaseUrl} from '../utils';
import Spinner from '../Spinner';

const ModalAprobarCupo = ({ 
    clienteId, refresh='', showOps=true
}) => {
    const [loading, setLoading] = useState(false);
    // const [cliente, setCliente] = useState(clienteOBJ);
    const [cliente, setCliente] = useState({
        id: '',
        documento: '',
        nombre: '',
        direccion: '',
        email: '',
        celular: '',
        nombreReferenciaPersonal: '',
        celularReferenciaPersonal: '',
        nombreReferenciaLaboral: '',
        celularReferenciaLaboral: '',
        clienteFoto: '',
        clienteFotoFrenteDoc: '',
        clienteFotoRevDoc: ''
    });
    const [cupo, setCupo] = useState('');
    const [nuevoCupo, setNuevoCupo] = useState('');
    
    useEffect(() => {
        const getClienteData = async () => {
            setLoading(true);
            if(!clienteId) { return; }
            try {
                const response = await axios.get( getAPIBaseUrl()+`clientes/byid/${clienteId}`);
                const res = response.data.message[0];
                // console.log('res:', res);
                
                if(res.cliente_id !== '') {
                    const getfotoultimocredito = await axios.get( `${getAPIBaseUrl()}creditos/getfotoultimocredito/byidcliente/${res.cliente_id}` );                
                    // console.log('credito_fotografia_cliente:', getfotoultimocredito.data);

                    setCliente({
                        id: res.cliente_id,
                        documento: res.cliente_documento,
                        nombre: res.cliente_nombre +' '+ res.cliente_apellidos,
                        direccion: res.cliente_direccion,
                        email: res.cliente_correo_electronico,
                        celular: res.cliente_celular,
                        nombreReferenciaPersonal: res.cliente_referencia_personal_nombre,
                        celularReferenciaPersonal: res.cliente_referencia_personal_celular,
                        nombreReferenciaLaboral: res.cliente_referencia_laboral_nombre,
                        celularReferenciaLaboral: res.cliente_referencia_laboral_celular,
                        clienteFoto: getfotoultimocredito.data.credito_fotografia_cliente,
                        clienteFotoFrenteDoc: getfotoultimocredito.data.credito_fotografia_frente_documento,
                        clienteFotoRevDoc: getfotoultimocredito.data.credito_fotografia_reverso_documento,
                    });

                    setCupo(res.cliente_cupo_aprobado);
                    setLoading(false);
                }
            } catch (error) {
                if(error.code !== 'ECONNABORTED') {
                    console.error( 'Error al traer los datos de los clientes', error );
                }
            }
        }

        getClienteData();
    }, [clienteId]);

    
    const handleChangeCupo = (e) => {
        setNuevoCupo(e.target.value);
    }

    const handleSubmitAprobarCupo = async (evt) => {
        evt.preventDefault();

        try {
            const response = await axios.post( getAPIBaseUrl()+`clientes/aprobarCupo`, {
                clienteId: cliente.id,
                cupo: nuevoCupo
            });

            if(!response.data.success) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error aprobar cupo',
                    text: response.data.message,
                    // footer: '<a href="">Why do I have this issue?</a>'
                });
                return;
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'Exito',
                    text: 'Cupo aprobado correctamente',
                    // footer: '<a href="">Why do I have this issue?</a>'
                });
            }

            setNuevoCupo('');
            setCliente({
                id: '',
                documento: '',
                nombre: '',
                direccion: '',
                email: '',
                celular: '',
                nombreReferenciaPersonal: '',
                celularReferenciaPersonal: '',
                nombreReferenciaLaboral: '',
                celularReferenciaLaboral: '',
                clienteFoto: '',
                clienteFotoFrenteDoc: '',
                clienteFotoRevDoc: ''
            });
            refresh();
            window.location.reload();

            const cerrar = document.querySelector('#modalAprobarCupo .close');
            cerrar.click();
        } catch (error) {
            // console.error( 'Error creando AprobarCupo', error );
            Swal.fire({
                icon: 'error',
                title: 'Error interno AprobarCupo',
                text: error,
                // footer: '<a href="">Why do I have this issue?</a>'
            });
            return;
        }
    }

    return (
        <form onSubmit={ handleSubmitAprobarCupo }>
            <div
                className="modal fade bs-example-modal-lg"
                id="modalAprobarCupo"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabelAprobarCupo"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myLargeModalLabelAprobarCupo">
                            { showOps ?
                                    <> Aprobar Cupo </>
                                :
                                    <> Información del cliente </>
                            }
                            </h4>
                            
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-hidden="true"
                            >
                                ×
                            </button>
                        </div>

                        <div className="modal-body text-center">
                            { loading ?
                                <Spinner sm={false}/>
                                :
                                <>
                                    <img
                                        src={`${cliente.clienteFoto ? cliente.clienteFoto : process.env.PUBLIC_URL + "/logo-creditxtatts.png"}`}
                                        className="img-fluid rounded-circle mb-10"
                                        alt="Foto cliente"
                                        style={{ 'width': '200px', 'height': '200px' }}
                                    />
                                    <br />
                                    <hr />

                                    <div className="row">
                                        <div className="col-md-6 text-left">
                                            <b>Documento:</b> {cliente.documento}
                                            <br />
                                            <b>Nombre:</b> {cliente.nombre}
                                            <br />
                                            <b>Direccion:</b> {cliente.direccion}
                                            <br />
                                            <b>Email:</b> {cliente.email}
                                            <br />
                                            <b>Celular:</b> {cliente.celular}
                                            <br />
                                            <b>Nombre Ref. Personal:</b> {cliente.nombreReferenciaPersonal}
                                            <br />
                                            <b>Celular Ref. Personal:</b> {cliente.celularReferenciaPersonal}
                                            <br />
                                            <b>Nombre Ref. Laboral:</b> {cliente.nombreReferenciaLaboral}
                                            <br />
                                            <b>Celular Ref. Laboral:</b> {cliente.celularReferenciaLaboral}
                                            <br />
                                            <b>Cupo Actual:</b> {convertirACOP(cupo)}
                                        </div>

                                        <div className="col-md-6 text-left">
                                            { showOps ?
                                                <>
                                                    {/* <hr /> */}
                                                    <div className="row">
                                                        <div className="col-md-12 col-sm-12">
                                                            <div className="form-group"> 
                                                                <label htmlFor="cupo"><b>Nuevo Cupo</b></label>
                                                                <div className="input-group mb-3">
                                                                    <div className="input-group-prepend">
                                                                        <span className="input-group-text">$</span>
                                                                    </div>
                                                                    <input
                                                                        className="form-control"
                                                                        id="cupo"
                                                                        name="cupo"
                                                                        type="number" 
                                                                        placeholder="Ingrese el nuevo monto del cupo"
                                                                        value={nuevoCupo}
                                                                        onChange={ handleChangeCupo }
                                                                        required 
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                              :
                                                <></>
                                            }
                                        </div>
                                    </div>

                                    {/* <div className="row">
                                        <div className="col-md-4"></div>
                                        <div className="col-md-8">
                                            <div className="text-left">
                                                <b>Documento:</b> {cliente.documento}
                                                <br />
                                                <b>Nombre:</b> {cliente.nombre}
                                                <br />
                                                <b>Direccion:</b> {cliente.direccion}
                                                <br />
                                                <b>Email:</b> {cliente.email}
                                                <br />
                                                <b>Celular:</b> {cliente.celular}
                                                <br />
                                                <b>Nombre Ref. Personal:</b> {cliente.nombreReferenciaPersonal}
                                                <br />
                                                <b>Celular Ref. Personal:</b> {cliente.celularReferenciaPersonal}
                                                <br />
                                                <b>Nombre Ref. Laboral:</b> {cliente.nombreReferenciaLaboral}
                                                <br />
                                                <b>Celular Ref. Laboral:</b> {cliente.celularReferenciaLaboral}
                                                <br />
                                                <b>Cupo Actual:</b> {convertirACOP(cupo)}
                                                { showOps ?
                                                    <>
                                                        <hr />
                                                        <div className="row">
                                                            <div className="col-md-12 col-sm-12">
                                                                <div className="form-group"> 
                                                                    <label htmlFor="cupo"><b>Nuevo Cupo</b></label>
                                                                    <div className="input-group mb-3">
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text">$</span>
                                                                        </div>
                                                                        <input
                                                                            className="form-control"
                                                                            id="cupo"
                                                                            name="cupo"
                                                                            type="number" 
                                                                            placeholder="Ingrese el nuevo monto del cupo"
                                                                            value={nuevoCupo}
                                                                            onChange={ handleChangeCupo }
                                                                            required 
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                                }
                                            </div>
                                        </div>
                                    </div> */}

                                    <br />
                                    <hr />

                                    <img
                                        src={`${cliente.clienteFotoFrenteDoc ? cliente.clienteFotoFrenteDoc : process.env.PUBLIC_URL + "/logo-creditxtatts.png"}`}
                                        className="img-fluid mb-10"
                                        alt="Foto frente documento cliente"
                                        style={{ 'height' : '50%', 'borderRadius': '13px' }}
                                    />
                                    <br />
                                    <hr />
                                    

                                    <img
                                        src={`${cliente.clienteFotoRevDoc ? cliente.clienteFotoRevDoc : process.env.PUBLIC_URL + "/logo-creditxtatts.png"}`}
                                        className="img-fluid mb-10"
                                        alt="Foto reverso documento cliente"
                                        style={{ 'height' : '50%', 'borderRadius': '13px' }}
                                    />
                                                
                                </>
                            }
                        </div>
                        
                        { showOps ?
                            <>
                                { loading ?
                                    <Spinner sm={false}/>
                                    :
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-dismiss="modal"
                                        >
                                            Cancelar
                                        </button>
                                        
                                        <button type="submit" className="btn btn-primary">
                                            Guardar
                                        </button>
                                    </div>
                                }
                            </>
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>
        </form>
    )
}

export default ModalAprobarCupo