import React, { useContext, useEffect, useState } from 'react';

import $ from 'jquery';
import axios from 'axios';
import Swal  from 'sweetalert2';
import ClienteFoto from '../Cliente/ClienteFoto';
import BootstrapSelectPicker from '../SelectPicker';
import { convertirACOP, calcularCredito, getAPIBaseUrl } from '../utils';
import {UserContext} from '../../context/UserContext';
import Spinner from '../Spinner';

require('bootstrap/js/dist/modal');

const ModalNuevoCredito = ({ clienteId }) => {
    const {user} = useContext(UserContext);

    const [loading, setLoading] = useState(false);
    const [credito, setCredito] = useState({
        clienteId : '',
        usuarioId : user[0].usuario_id,
        comercioId : user[0].comercio_id,

        // Form
        nuevoCreditoMonto : '',
        nuevoCreditoProductoServicio : '',
        nuevoCreditoNroCuotas : '',
        nuevoCreditoFrecuencia : '',

        // Valores calculados
        nuevoCreditoValorCuota : '',
        nuevoCreditoInteres : '',
        nuevoCreditoAval : '',
        nuevoCreditoTotalPagar : '',

        // Fotos
        nuevoCreditoFotografiaCliente : '',
        nuevoCreditoFotografiaFrenteDoc : '',
        nuevoCreditoFotografiaRevDoc : '',

        validarFotoCliente: false,
        validarFotoFrenteDoc: false,
        validarFotoRevDoc: false,

        limpiarFoto: false
    });
    const [esMovil, setEsmovil] = useState(false);
    const [hndlFocus, setHndlFocus] = useState(false);

    /**
     * Detectar redimensión de la ventana para adaptarse a móviles
     */
    useEffect(() => {
        window.addEventListener('resize', (evt) => {
            evt.preventDefault();

            if (window.innerWidth <= 768) {
                // console.log("Es un móvil");
                setEsmovil(true);
            } else {
                // console.log(" No es un móvil");
                setEsmovil(false);
            }
        });
    }, []);

    useEffect(() => {
        $('#modalNuevoCredito').on('show.bs.modal', function (e) {
            setCredito({
                clienteId : '',
                usuarioId : user[0].usuario_id,
                comercioId : user[0].comercio_id,
        
                // Form
                nuevoCreditoMonto : '',
                nuevoCreditoProductoServicio : '',
                nuevoCreditoNroCuotas : '',
                nuevoCreditoFrecuencia : '',
        
                // Valores calculados
                nuevoCreditoValorCuota : '',
                nuevoCreditoInteres : '',
                nuevoCreditoAval : '',
                nuevoCreditoTotalPagar : '',
        
                // Fotos
                nuevoCreditoFotografiaCliente : '',
                nuevoCreditoFotografiaFrenteDoc : '',
                nuevoCreditoFotografiaRevDoc : '',
        
                validarFotoCliente: false,
                validarFotoFrenteDoc: false,
                validarFotoRevDoc: false,

                limpiarFoto: false
            });
        })
    }, [user]);


    const handleChange = (evt) => {
        /*
            evt.target es el elemento que ejecuto el evento
            name identifica el input y value describe el valor actual
        */
        const { target }      = evt;
        const { name, value } = target;

        /*
            Este snippet:
            1. Clona el estado actual
            2. Reemplaza solo el valor del
            input que ejecutó el evento
        */
        const newCredito = {
            ...credito,
            [name]: value,
        };

        // console.log('newCredito:', newCredito);

        // Sincroniza el estado de nuevo
        setCredito(newCredito);
    }

    const validarCupo = async (montoCredito) => {
        if(!hndlFocus) {
            setHndlFocus(true);
            const getCreditosCupoByid = await axios.get( `${getAPIBaseUrl()}clientes/creditoscupobyid/${clienteId}` );
            // console.log('getCreditosCupoByid:', getCreditosCupoByid.data.message);
            const cupoDisponible = getCreditosCupoByid.data.message.cupo_disponible;

            // console.log('(montoCredito:', montoCredito);
            // console.log('cupoDisponible:', cupoDisponible);

            // console.log('(montoCredito:', parseFloat(montoCredito));
            // console.log('cupoDisponible:', parseFloat(cupoDisponible));

            if(parseFloat(montoCredito) > parseFloat(cupoDisponible)) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'El monto solicitado para el crédito ('+ convertirACOP(montoCredito) +') sobrepasa el cupo disponible ('+ convertirACOP(cupoDisponible) +')',
                    // footer: '<a href="">Why do I have this issue?</a>'
                }).then((result) => {
                    if(result.isConfirmed) {
                        if(esMovil) {
                            setTimeout(() => {
                                document.getElementById('nuevoCreditoMonto').focus();
                            }, 300);
                        }
                    }
                });
            }
        }
    }

    const handleBlurValidarCupo = async (e) => {
        const montoCredito = e.target.value;
        // console.log('monto del credito:', montoCredito);
        validarCupo(montoCredito);
    }

    const handleFocusValidarCupo = async () => {
        if(hndlFocus) { setHndlFocus(false); }
    }

    const handleSubmitCrearCredito = async (e) => {
        e.preventDefault();

        // console.log('Crear Credito');

        //++++++++++++++++++++++++++++++++

        let monto = credito.nuevoCreditoMonto;
        let nroCuotas = credito.nuevoCreditoNroCuotas;
        let modalidad = credito.nuevoCreditoFrecuencia;

        let creditoCalc = calcularCredito(monto, nroCuotas, modalidad);
        
        let totalPagar = creditoCalc[0].totalPagar;
        let valorInteres = creditoCalc[0].valorInteres;
        let valorAval = creditoCalc[0].valorAval;
        let valorCuota = creditoCalc[0].valorCuota;
        let valorCapitalCuota = creditoCalc[0].valorCapitalCuota;
        let valorInteresCuota = creditoCalc[0].valorInteresCuota;
        let valorAvalCuota = creditoCalc[0].valorAvalCuota;
        
        credito.clienteId = clienteId;
        credito.nuevoCreditoValorCuota = valorCuota;
        credito.nuevoCreditoInteres = valorInteres;
        credito.nuevoCreditoAval = valorAval;
        credito.nuevoCreditoTotalPagar = totalPagar;

        //++++++++++++++++++++++++++++++++

        if(credito.nuevoCreditoFotografiaCliente === '') {
            setCredito({
                ...credito,
                validarFotoCliente : true
            });
            return;
        } else {
            setCredito({
                ...credito,
                validarFotoCliente : false
            });
        }

        if(credito.nuevoCreditoFotografiaFrenteDoc === '') {
            setCredito({
                ...credito,
                validarFotoFrenteDoc : true
            });
            return;
        } else {
            setCredito({
                ...credito,
                validarFotoFrenteDoc : false
            });
        }

        if(credito.nuevoCreditoFotografiaRevDoc === '') {
            setCredito({
                ...credito,
                validarFotoRevDoc : true
            });
            return;
        } else {
            setCredito({
                ...credito,
                validarFotoRevDoc : false
            });
        }

        // console.log('credito:', credito);

        setLoading(true);

        try {
            const data = new FormData();
            data.append('clienteId', credito.clienteId);
            data.append('usuarioId', credito.usuarioId);
            data.append('comercioId', credito.comercioId);
            data.append('nuevoCreditoValorCuota', credito.nuevoCreditoValorCuota);
            data.append('nuevoCreditoInteres', credito.nuevoCreditoInteres);
            data.append('nuevoCreditoAval', credito.nuevoCreditoAval);
            data.append('nuevoCreditoTotalPagar', credito.nuevoCreditoTotalPagar);
            data.append('nuevoCreditoMonto', credito.nuevoCreditoMonto);
            data.append('nuevoCreditoProductoServicio', credito.nuevoCreditoProductoServicio);
            data.append('nuevoCreditoNroCuotas', credito.nuevoCreditoNroCuotas);
            data.append('nuevoCreditoFrecuencia', credito.nuevoCreditoFrecuencia);
            data.append('nuevoCreditoFotografiaCliente', credito.nuevoCreditoFotografiaCliente);
            data.append('nuevoCreditoFotografiaFrenteDoc', credito.nuevoCreditoFotografiaFrenteDoc);
            data.append('nuevoCreditoFotografiaRevDoc', credito.nuevoCreditoFotografiaRevDoc);

            if(validarCupo(monto)) {
                // Insertar credito
                const responseCredito = await axios.post( `${getAPIBaseUrl()}creditos/nuevo`, data);

                // console.log( 'responseCredito:', responseCredito);
                // console.log('blob3:', responseCredito.data.nuevoCreditoFotografiaCliente instanceof Blob);

                // console.log( 'responseCredito.data:', responseCredito.data.lastInsertId);

                // let creditoDataObj = JSON.parse(responseCredito.data);
                // console.log('creditoDataObj Credito:', creditoDataObj);

                // if(responseCredito.data.lastInsertId) {
                    try {
                        // Insertar cuotas
                        for (let i = 1; i <= nroCuotas; i++) {
                            const cuota = {
                                creditoId: responseCredito.data.lastInsertId,
                                usuarioId: credito.usuarioId,
                                cuotaNumero: i,
                                capital: valorCapitalCuota,
                                interesCorriente: valorInteresCuota,
                                aval: valorAvalCuota,
                                cuotaTotal: valorCuota,
                                modalidad: modalidad,
                            };
        
                            await axios.post( `${getAPIBaseUrl()}cuotas/nuevo`, cuota );
                        }

                        setLoading(false);
            
                        setCredito({
                            clienteId : '',
                            usuarioId : user[0].usuario_id,
                            comercioId : user[0].comercio_id,
                    
                            // Form
                            nuevoCreditoMonto : '',
                            nuevoCreditoProductoServicio : '',
                            nuevoCreditoNroCuotas : '',
                            nuevoCreditoFrecuencia : '',
                    
                            // Valores calculados
                            nuevoCreditoValorCuota : '',
                            nuevoCreditoInteres : '',
                            nuevoCreditoAval : '',
                            nuevoCreditoTotalPagar : '',
                    
                            // Fotos
                            nuevoCreditoFotografiaCliente : '',
                            nuevoCreditoFotografiaFrenteDoc : '',
                            nuevoCreditoFotografiaRevDoc : '',
                    
                            validarFotoCliente: false,
                            validarFotoFrenteDoc: false,
                            validarFotoRevDoc: false,

                            limpiarFoto: true
                        });
                        
                        const modalClose = document.querySelector("#modalNuevoCredito .close");
                        modalClose.click();

                        const buscarCliente = document.querySelector("#submitBuscarCliente");
                        buscarCliente.click();

                        Swal.fire({
                            title: 'Credito Creado!',
                            text: 'Correctamente!',
                            icon: 'success',
                            confirmButtonText: 'Ok',
                            // showCancelButton: true,
                            confirmButtonClass: 'btn btn-success',
                            // cancelButtonClass: 'btn btn-danger'
                        }).then((result) => {
                            console.log('result:', result);
                        });
                    } catch (errorCuota) {
                        console.error( 'Error creando cuota', errorCuota );
                    }
                // }
            }
        } catch (errorCredito) {
            console.error( 'Error creando crédito', errorCredito );
        }
    }

    return (
        <div
            className="modal fade bs-example-modal-lg"
            id="modalNuevoCredito"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <form onSubmit={ handleSubmitCrearCredito } encType="multipart/form-data">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myLargeModalLabel">
                                Nuevo Crédito
                            </h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-hidden="true"
                            >
                                ×
                            </button>
                        </div>

                        <div className="modal-body">
                            { esMovil ? 
                                <>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="nuevoCreditoMonto">Monto del Crédito</label>
                                                <input
                                                    className="form-control"
                                                    id="nuevoCreditoMonto"
                                                    name="nuevoCreditoMonto"
                                                    type="number"
                                                    placeholder="Monto del Crédito"
                                                    value={ credito.nuevoCreditoMonto }
                                                    onChange={ handleChange }
                                                    onBlur={ handleBlurValidarCupo }
                                                    onFocus={ handleFocusValidarCupo }
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-12">
                                            <div className="form-group"> 
                                                <label htmlFor="nuevoCreditoProductoServicio">Producto o Servicio</label>
                                                <input
                                                    className="form-control"
                                                    id="nuevoCreditoProductoServicio"
                                                    name="nuevoCreditoProductoServicio"
                                                    type="text" 
                                                    placeholder="Producto o Servicio"
                                                    value={ credito.nuevoCreditoProductoServicio }
                                                    onChange={ handleChange }
                                                    required 
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-md-6 col-sm-12">
                                            <div className="form-group"> 
                                                <label htmlFor="nuevoCreditoNroCuotas">Número de Cuotas</label>
                                                {/* <input
                                                    className="form-control"
                                                    id="nuevoCreditoNroCuotas"
                                                    name="nuevoCreditoNroCuotas"
                                                    type="text" 
                                                    placeholder="Número de Cuotas"
                                                    value={ credito.nuevoCreditoNroCuotas }
                                                    onChange={ handleChange }
                                                    required 
                                                /> */}

                                                <BootstrapSelectPicker 
                                                    idNameSelect={'nuevoCreditoNroCuotas'}
                                                    selectValue={credito.nuevoCreditoNroCuotas}
                                                    handleChange={handleChange}
                                                    options={[
                                                        { value: 4, name: 4 },
                                                        { value: 5, name: 5 },
                                                        { value: 6, name: 6 },
                                                        { value: 7, name: 7 },
                                                        { value: 8, name: 8 },
                                                    ]}
                                                    required={true}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="nuevoCreditoFrecuencia">Modalidad de pago</label>
                                                <BootstrapSelectPicker
                                                    idNameSelect={'nuevoCreditoFrecuencia'}
                                                    selectValue={credito.nuevoCreditoFrecuencia}
                                                    handleChange={handleChange}
                                                    options={[
                                                        { value: 'Semanal', name: 'Semanal' },
                                                        { value: 'Quincenal', name: 'Quincenal' },
                                                    ]}
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <ClienteFoto
                                        tituloLabel={ 'Fotografía del cliente:' }
                                        idFileElem={ 'fileElemFotoDocPersona' }
                                        idFileList={ 'fileListFotoDocPersona' }
                                        idImg={ 'imgFotoDocPersona' }
                                        idFormGroup={ 'fgFotoDocPersona' }
                                        credito={ credito }
                                        setCredito={ setCredito }
                                    />

                                    <ClienteFoto
                                        tituloLabel={ 'Fotografía del frente del documento:' }
                                        idFileElem={ 'fileElemFotoDocFrente' }
                                        idFileList={ 'fileListFotoDocFrente' }
                                        idImg={ 'imgFotoDocFrente' }
                                        idFormGroup={ 'fgFotoDocFrente' }
                                        credito={ credito }
                                        setCredito={ setCredito }
                                    />

                                    <ClienteFoto
                                        tituloLabel={ 'Fotografía del reverso del documento:' }
                                        idFileElem={ 'fileElemFotoDocReverso' }
                                        idFileList={ 'fileListFotoDocReverso' }
                                        idImg={ 'imgFotoDocReverso' }
                                        idFormGroup={ 'fgFotoDocReverso' }
                                        credito={ credito }
                                        setCredito={ setCredito }
                                    />
                                </>
                              : 
                                <div className="alert alert-danger" role="alert">
                                    <b>Para solicitar un crédito debe hacer el trámite desde un dispositivo móvil.</b>
                                </div>
                            }
                        </div>

                        { esMovil ? 
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    disabled={ loading }
                                >
                                    Cancelar
                                </button>
                                <button type="submit" className="btn btn-primary" disabled={ loading }>
                                    { loading ? <Spinner sm={true}/> : <></> }
                                    Crear
                                </button>
                            </div>
                          :
                            <></>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ModalNuevoCredito