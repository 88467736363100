import {useEffect, useState} from "react";
import ModalNuevoComercio from "./ModalNuevoComercio"
import axios from "axios";
import {getAPIBaseUrl} from "../utils";

import $ from 'jquery';
import "gridjs/dist/theme/mermaid.min.css";
import { Grid } from 'gridjs-react';
import { esES } from "gridjs/l10n";
import { _ } from "gridjs-react";
import ModalEditarComercio from "./ModalEditarComercio";

const Comercios = () => {
    const [comercios, setComercios] = useState([]);
    const [loading, setLoading] = useState(true);
    const [comercioId, setComercioId] = useState('');

    const getComercios = async () => {
        try {
            const responseComercios = await axios.get( getAPIBaseUrl()+`comercios`);
            // console.log( 'responseComercios:', responseComercios.data);
            setComercios(responseComercios.data);
            
        } catch (error) {
            console.error( 'Error al traer los datos de los comercios', error );
        }
    }

    useEffect(() => {
        getComercios();

        $(function () {
            setTimeout(() => {
                // console.log('Init tooltip ...');
                $('[data-toggle="tooltip"]').tooltip();
                setLoading(false);
            }, 3000);
        });
    }, []);

    return (
        <>
            <div className="pd-20 card-box mb-30">
                <div className="row mb-20">
                    <div className="col-md-8">
                        <div className="clearfix">
                            <div className="pull-left">
                                <h4 className="text-blue h4">Comercios</h4>
                                {/* <p className="mb-30">Cargar información de un cliente</p> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <button
                            type="button"
                            id="btnNuevoComercio"
                            className="btn btn-block btn-outline-success"
                            data-backdrop="static"
                            data-toggle="modal"
                            data-target="#modalNuevoComercio"
                        >
                            Nuevo Comercio
                        </button>
                    </div>
                </div>

                <div className="table-responsive">
                <Grid
                    columns={ ['Id', 'Nit', 'Nombre', 'Dirección', 'Teléfono', 'Email', 'Acciones'] }
                    data={comercios.map(comercio => [
                        comercio.comercio_id,
                        comercio.comercio_nit,
                        comercio.comercio_nombre,
                        comercio.comercio_direccion,
                        comercio.comercio_telefono,
                        comercio.comercio_email,
                        _(
                            loading ? 
                                <> Cargando ... </>
                            :
                                <>
                                    <span 
                                        data-toggle="tooltip"
                                        title="Editar"
                                    >
                                        <button 
                                            type="button" 
                                            className="btn btn-sm" 
                                            data-bgcolor="#00b489" 
                                            data-color="#ffffff"
                                            style={{ 'color': 'rgb(255, 255, 255)', 'backgroundColor': 'rgb(0, 180, 137)' }}
                                            id={comercio.comercio_id+"-editar-comercio"}
                                            data-backdrop="static"
                                            data-toggle="modal"
                                            data-target="#modalEditarComercio"
                                            onClick={ () => { setComercioId(comercio.comercio_id) } }
                                        >
                                            <i className="icon-copy ion-android-create"></i>
                                        </button>
                                    </span>
                                    &nbsp;
                                    <span 
                                        data-toggle="tooltip"
                                        title="Eliminar"
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-sm"
                                            data-bgcolor="#bd081c"
                                            data-color="#ffffff"
                                            style={{ 'color': 'rgb(255, 255, 255)', 'backgroundColor': 'rgb(189, 8, 28)' }}
                                            id={comercio.comercio_id+"-eliminar-comercio"}
                                            // onClick={ handleEliminarCliente(cliente.cliente_id, cliente.cliente_documento) }
                                        >
                                            <i className="icon-copy ion-android-cancel"></i>
                                        </button>
                                    </span>
                                </>
                        )
                    ])}
                    sort={ true }
                    search={ true }
                    pagination={{ limit: 8 }}
                    language={ esES }
                />
                </div>
            </div>

            <ModalNuevoComercio />

            <ModalEditarComercio
                comercioId={ comercioId }
                refresh={ getComercios }
            />
        </>
    )
}

export default Comercios