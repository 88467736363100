import { useEffect, useState } from "react";
import axios from "axios";
import { filterCreditos, busquedaCreditos, getAPIBaseUrl } from "../utils";
import Spinner from "../Spinner";
import Credito from "./Credito";
import ModalAprobarCupo from "../Cliente/ModalAprobarCupo";

const CreditosAprobar = () => {
  const [loading, setLoading] = useState(false);
  const [creditosData, setCreditosData] = useState("");
  const [buscarCreditos, setBuscarCreditos] = useState("");
  const [creditosPendientes, setCreditosPendientes] = useState([]);
  const [creditosVigentes, setCreditosVigentes] = useState([]);
  const [creditosRetrasados, setCreditosRetrasados] = useState([]);
  const [creditosRechazados, setCreditosRechazados] = useState([]);
  const [creditosPagados, setCreditosPagados] = useState([]);
  const [clienteId, setClienteId] = useState("");

  const setCreditosByEstado = async () => {
    await axios.get(`${getAPIBaseUrl()}creditos/calcularDiasInteresesMoraCreditos`);

    const getCreditos = axios.get(`${getAPIBaseUrl()}creditos`);
    // console.log('getCreditos:', getCreditos);
    getCreditos.then((creditosInfo) => 
    {
      const creditos = creditosInfo.data.message;
      // console.log("creditos:", creditos);
      
      if (creditos) {
        setCreditosData(creditos);

        setCreditosPendientes(filterCreditos(creditos, "pendiente"));
        setCreditosVigentes(filterCreditos(creditos, "vigente"));
        setCreditosRetrasados(filterCreditos(creditos, "retrasado"));
        setCreditosRechazados(filterCreditos(creditos, "rechazado"));
        setCreditosPagados(filterCreditos(creditos, "pagado"));
      }

      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    setCreditosByEstado();
  }, []);

  const handleBuscarCreditos = (e) => {
    // console.log('buscarCreditos:', e.target.value);

    setBuscarCreditos(e.target.value);

    // console.log('creditosData:', creditosData);
    if (creditosData) {
      const creditos = busquedaCreditos(creditosData, e.target.value);
      // console.log("creditos busqueda:", creditos);

      setCreditosPendientes(filterCreditos(creditos, "pendiente"));
      setCreditosVigentes(filterCreditos(creditos, "vigente"));
      setCreditosRetrasados(filterCreditos(creditos, "retrasado"));
      setCreditosRechazados(filterCreditos(creditos, "rechazado"));
      setCreditosPagados(filterCreditos(creditos, "pagado"));
    }
  };

  return (
    <>
      <div className="pd-20 card-box mb-30">
        <div className="row mb-20">
          <div className="col-md-8">
            <div className="clearfix">
              <div className="pull-left">
                <h4 className="text-blue h4">Creditos</h4>
                {/* <p className="mb-30">Aprobar creditos</p> */}
              </div>
            </div>
          </div>

          <div className="col-md-4"></div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 mb-30">
            {loading ? (
              <div className="row text-center">
                <div className="col-md-12">
                  <Spinner />
                  <br />
                  <b>Generando datos de créditos ...</b>
                  <br />
                  <small>
                    Puede tardar varios segundos, así que ten paciencia!
                  </small>
                </div>
              </div>
            ) : (
              <>
                <input
                  type="search"
                  id="buscarCreditos"
                  name="buscarCreditos"
                  placeholder="Buscar..."
                  className="gridjs-input gridjs-search-input"
                  value={buscarCreditos}
                  onChange={handleBuscarCreditos}
                ></input>

                <br />
                <br />

                <ul className="nav nav-tabs customtab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#creditosPendientes"
                      role="tab"
                      aria-selected="true"
                    >
                      Pendientes ({creditosPendientes.length})
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#creditosVigentes"
                      role="tab"
                      aria-selected="true"
                    >
                      Vigentes ({creditosVigentes.length})
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#creditosRetrasados"
                      role="tab"
                      aria-selected="false"
                    >
                      Retrasados ({creditosRetrasados.length})
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#creditosRechazados"
                      role="tab"
                      aria-selected="false"
                    >
                      Rechazados ({creditosRechazados.length})
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#creditosHistorico"
                      role="tab"
                      aria-selected="false"
                    >
                      Pagados ({creditosPagados.length})
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="creditosPendientes"
                    role="tabpanel"
                  >
                    <div className="pd-10 faq-wrap">
                      <div className="accordion" id="accordion1">
                        {creditosPendientes.length > 0 ? (
                          creditosPendientes.map((creditoPend) => (
                            <Credito
                              key={creditoPend.credito_id}
                              creditoTipo={"pendiente"}
                              credito={creditoPend}
                              dataParentElem={"#accordion1"}
                              getCreditos={setCreditosByEstado}
                              setClienteId={setClienteId}
                            />
                          ))
                        ) : (
                          <b> No hay créditos pendientes para mostrar </b>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="creditosVigentes"
                    role="tabpanel"
                  >
                    <div className="pd-10 faq-wrap">
                      <div className="accordion" id="accordion2">
                        {creditosVigentes.length > 0 ? (
                          creditosVigentes.map((creditoVig) => (
                            <Credito
                              key={creditoVig.credito_id}
                              creditoTipo={"vigente"}
                              credito={creditoVig}
                              dataParentElem={"#accordion2"}
                              getCreditos={setCreditosByEstado}
                              setClienteId={setClienteId}
                            />
                          ))
                        ) : (
                          <b> No hay creditos vigentes que mostrar </b>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="creditosRetrasados"
                    role="tabpanel"
                  >
                    <div className="pd-10 faq-wrap">
                      <div id="accordion4">
                        {creditosRetrasados.length > 0 ? (
                          creditosRetrasados.map((creditoRetra) => (
                            <Credito
                              key={creditoRetra.credito_id}
                              creditoTipo={"retrasado"}
                              credito={creditoRetra}
                              dataParentElem={"#accordion4"}
                              getCreditos={setCreditosByEstado}
                              setClienteId={setClienteId}
                            />
                          ))
                        ) : (
                          <b> No hay creditos retrasados que mostrar </b>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="creditosRechazados"
                    role="tabpanel"
                  >
                    <div className="pd-10 faq-wrap">
                      <div id="accordion5">
                        {creditosRechazados.length > 0 ? (
                          creditosRechazados.map((creditoRecha) => (
                            <Credito
                              key={creditoRecha.credito_id}
                              creditoTipo={"rechazado"}
                              credito={creditoRecha}
                              dataParentElem={"#accordion5"}
                              getCreditos={setCreditosByEstado}
                              setClienteId={setClienteId}
                            />
                          ))
                        ) : (
                          <b> No hay creditos rechazados que mostrar </b>
                        )}
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="creditosHistorico"
                    role="tabpanel"
                  >
                    <div className="pd-10 faq-wrap">
                      <div className="accordion" id="accordion3">
                        {creditosPagados.length > 0 ? (
                          creditosPagados.map((creditoHisto) => (
                            <Credito
                              key={creditoHisto.credito_id}
                              creditoTipo={"historico"}
                              credito={creditoHisto}
                              dataParentElem={"#accordion3"}
                              getCreditos={setCreditosByEstado}
                              setClienteId={setClienteId}
                            />
                          ))
                        ) : (
                          <b> No hay creditos pagados que mostrar </b>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Inicio Modal Aprobar Cupo */}
      <ModalAprobarCupo clienteId={clienteId} showOps={false} />
      {/* Fin Modal Aprobar Cupo */}
    </>
  );
};

export default CreditosAprobar;
