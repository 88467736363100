import { useContext } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { UserContext } from './context/UserContext.js';

// import AppTestData           from './AppTestData';
import Login         from './components/Usuario/Login';
import Root          from './Root.js';
import Cliente       from './components/Cliente/Cliente.js';
import Simulador     from './components/Simulador/Simulador.js';
import Reportes from './components/Credito/Reportes.js';
// import Configuracion from './components/Configuracion/Configuracion.js';
import Perfil        from './components/Usuario/Perfil.js';
import ErrorPage     from './ErrorPage.js';
import Comercios from './components/Comercio/Comercios.js';
import ClientesCupo from './components/Cliente/ClientesCupo.js';
import CreditosAprobar from './components/Credito/CreditosAprobar.js';
// import ProtectedRoute from './ProtectedRoute.js';
// import Usuarios from './components/Usuario/Usuarios.js';

function App() {
  const {user} = useContext(UserContext);

  const router = createBrowserRouter([
    {
      path: '/',
      // element: setTimeout(() => {!user ?  <Login />  : <Root />}),
      element: user ? <Root /> : <Login />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: user && user[0].usuario_rol === 'admincom' ?
            <Navigate to="/clientes" replace />  :  <Navigate to="/comercios" replace />
        },
        {
          path: 'clientes',
          element: user && user[0].usuario_rol === 'admincom' ? <Cliente /> : <Navigate to="/" replace />,
        },
        {
          path: 'comercios',
          element: user && user[0].usuario_rol === 'admin' ? <Comercios /> : <Navigate to="/" replace />,
        },
        {
          path: 'clientescupo',
          element: user && user[0].usuario_rol === 'admin' ? <ClientesCupo /> : <Navigate to="/" replace />,
        },
        {
          path: 'creditosaprobar',
          element: user && user[0].usuario_rol === 'admin' ? <CreditosAprobar /> : <Navigate to="/" replace />,
        },
        {
          path: 'simulador',
          element: user ? <Simulador /> : <Navigate to="/" replace />,
        },
        {
          path: 'reportes',
          element: user ? <Reportes /> : <Navigate to="/" replace />,
        },
        {
          path: 'perfil',
          element: user ? <Perfil /> : <Navigate to="/" replace />,
        },
        // {
        //   path: 'configuracion',
        //   element: <Configuracion />,
        // },
        // {
        //   path: 'usuarios',
        //   element: <Usuarios />,
        // },
        // {
        //   path: 'appTestData',
        //   element: <AppTestData />,
        // },
      ],
    },
  ]);

  return (
    <RouterProvider router={router} />
  )
}

export default App;
