import $ from 'jquery';
// eslint-disable-next-line no-unused-vars
import { tooltip } from "bootstrap";

/**
 * @returns URL de la API consumida
 */
export const getAPIBaseUrl = () => {
    /**
     * Devolver la URL de la API
     * Produccion / Desarrollo
     */
    return (window.location.host === 'creditxtatts.com') ?
        `https://creditxtatts.com/creditxtatts/api/` 
    : 
        `http://${window.location.host.replace('3000', '8080')}/creditxtatts/api/`;
}

/**
 * Dar formato de peso colombiano al dinero
 * @param {*} valor
 * @returns
 */
export const convertirACOP = (valor) => {
    return new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        // maximumSignificantDigits: 3,
        maximumFractionDigits: 3,
        // minimumFractionDigits: 0
    }).format(valor);

    // return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', }).format(
    //     valor
    // );
}

/**
 * Calcular los valores del credito
 * 
 * @param {*} monto 
 * @param {*} nroCuotas
 * @param {*} modalidad
 * @returns
 */
export const calcularCredito = (monto, nroCuotas, modalidad) => {
    // @TODO: validar Maximo 8 cuotas, minimo 4
    let interes, aval;

    if(modalidad === 'Quincenal') {
        interes = 0.02;
        aval = 0.02;
    } else if(modalidad === 'Semanal') {
        interes = 0.015;
        aval = 0.015;
    }

    // valorFinal = monto(1+(interes * nroCuotas))
    let valorFinal = monto * (1 + ((interes + aval) * nroCuotas));
    let valorInteresAval = (valorFinal - monto) / 2;
    let valorCuota = valorFinal / nroCuotas;
    let valorInteresCuota = valorInteresAval / nroCuotas;
    let valorAvalCuota = valorInteresAval / nroCuotas;
    let valorCapitalCuota = valorCuota - (valorInteresCuota + valorAvalCuota);
    let valores = [
        {
            totalPagar: valorFinal,
            valorInteres: valorInteresAval,
            valorAval: valorInteresAval,
            valorCuota: valorCuota,

            valorCapitalCuota: valorCapitalCuota,
            valorInteresCuota: valorInteresCuota,
            valorAvalCuota: valorAvalCuota,
        }
    ];
    return valores;
}

export const toggleLeftMenu = () => {
    // $(this).toggleClass('open');
    $("body").toggleClass("sidebar-shrink");
    $(".left-side-bar").toggleClass("open");
    $(".mobile-menu-overlay").toggleClass("show");
}

export const filterCreditos = (items, estado) => {
    if(items.length === 0 || estado === '') { return items; }
    return items.filter((item) => item.credito_estado === estado );
}

export const busquedaCreditos = (items, criterio) => {
    if(criterio === '') { return items; }
    return items.filter((item) =>
        item.cliente_documento.toLowerCase().includes(criterio.toLowerCase()) ||
        item.cliente_nombre.toLowerCase().includes(criterio.toLowerCase()) ||
        item.cliente_apellidos.toLowerCase().includes(criterio.toLowerCase())
    );
}

/**
 * Habilitar tooltips
 */
export const initTooltip = () => {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip();
    });
}