import axios from 'axios';
import {useRef, useState} from 'react';
import Swal from 'sweetalert2';
import {getAPIBaseUrl} from '../utils';

const ModalNuevoComercio = () => {
    const lastInsertComercioIdRef = useRef('');
    const [formDataComercio, setFormDataComercio] = useState({
        comercioNit: '',
        comercioNombre: '',
        comercioDireccion: '',
        comercioTelefono: '',
        comercioEmail: ''
    });

    const [formDataUsuario, setFormDataUsuario] = useState({
        comercioId: '',
        usuarioNombres: '',
        usuarioApellidos: '',
        usuarioDireccion: '',
        usuarioTipoDocumento: '-',
        usuarioDocumento: '',
        usuarioCelular: '',
        usuarioCorreoElectronico: '',
        usuarioContrasenia: '',
        usuarioRol: 'admincom'
    });


    const handleChangeComercio = (e) => {
        setFormDataComercio({
            ...formDataComercio,
            [e.target.name]: e.target.value
        });
    }

    const handleChangeUsuario = (e) => {
        setFormDataUsuario({
            ...formDataUsuario,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmitCrearComercio = async (evt) => {
        evt.preventDefault();

        try {
            const response = await axios.post( getAPIBaseUrl()+`comercios/nuevo`, formDataComercio );
            console.log( 'response comercio:', response.data);
            if(!response.data.success) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error comercio',
                    text: response.data.message,
                    // footer: '<a href="">Why do I have this issue?</a>'
                });
                return;
            } else {
                const lastInsertComercioId = response.data.lastInsertComercioId;
                // setFormDataUsuario({
                //     ...formDataUsuario,
                //     comercioId: lastInsertComercioId
                // });
                formDataUsuario.comercioId = lastInsertComercioId;
                lastInsertComercioIdRef.current = lastInsertComercioId;
            }

        } catch (error) {
            // console.error( 'Error creando comercio', error );
            Swal.fire({
                icon: 'error',
                title: 'Error interno comercio',
                text: error,
                // footer: '<a href="">Why do I have this issue?</a>'
            });
            return;
        }


        // Crear usuario admin del comercio
        try {
            const response = await axios.post( getAPIBaseUrl()+`usuarios/nuevo`, formDataUsuario);
            // console.log( 'response usuario:', response.data);

            if(!response.data.success) {
                // console.log('comercioId:', formDataUsuario.comercioId);
                // console.log('comercioIdRef:', lastInsertComercioIdRef.current);
                await axios.post(`${getAPIBaseUrl()}comercios/eliminar`, {id: formDataUsuario.comercioId});

                Swal.fire({
                    icon: 'error',
                    title: 'Error usuario',
                    text: response.data.message,
                    // footer: '<a href="">Why do I have this issue?</a>'
                });
                return;

            } else {
                Swal.fire({
                    title: 'Comercio Creado!',
                    text: 'Correctamente!',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    // showCancelButton: true,
                    confirmButtonClass: 'btn btn-success',
                    // cancelButtonClass: 'btn btn-danger'
                }).then((result) => {
                    // console.log('result:', result);
                    if(result.isConfirmed) {
                        setFormDataComercio({
                            comercioNit: '',
                            comercioNombre: '',
                            comercioDireccion: '',
                            comercioTelefono: '',
                            comercioEmail: ''
                        });

                        setFormDataUsuario({
                            comercioId: '',
                            usuarioNombres: '',
                            usuarioApellidos: '',
                            usuarioDireccion: '',
                            usuarioTipoDocumento: '-',
                            usuarioDocumento: '',
                            usuarioCelular: '',
                            usuarioCorreoElectronico: '',
                            usuarioContrasenia: '',
                            usuarioRol: 'admincom'
                        });

                        const cerrar = document.querySelector('#modalNuevoComercio .close');
                        cerrar.click();
                    }
                });
            }

        } catch (error) {
            console.log('comercioId:', formDataUsuario.comercioId);
            console.log('comercioIdRef:', lastInsertComercioIdRef.current);
            await axios.post(`${getAPIBaseUrl()}comercios/eliminar`, {id: formDataUsuario.comercioId});

            // console.error( 'Error creando usuario', error );
            Swal.fire({
                icon: 'error',
                title: 'Error interno usuario',
                text: error,
                // footer: '<a href="">Why do I have this issue?</a>'
            });
            return;
        }
    }

    return (
        <form onSubmit={ handleSubmitCrearComercio }>
            <div
                className="modal fade bs-example-modal-lg"
                id="modalNuevoComercio"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabelComercio"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myLargeModalLabelComercio">
                                Nuevo Comercio
                            </h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-hidden="true"
                            >
                                ×
                            </button>
                        </div>

                        <div className="modal-body">
                            
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="comercioNit">Nit</label>
                                        <input
                                            className="form-control"
                                            id="comercioNit"
                                            name="comercioNit"
                                            type="number" 
                                            placeholder="Nit"
                                            value={formDataComercio.comercioNit}
                                            onChange={ handleChangeComercio }
                                            required 
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="comercioNombre">Razon Social</label>
                                        <input
                                            className="form-control"
                                            id="comercioNombre"
                                            name="comercioNombre"
                                            type="text"
                                            placeholder="Nombre"
                                            value={ formDataComercio.comercioNombre }
                                            onChange={ handleChangeComercio }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="comercioDireccion">Dirección</label>
                                        <input
                                            className="form-control"
                                            id="comercioDireccion"
                                            name="comercioDireccion"
                                            type="text"
                                            placeholder="Dirección"
                                            value={ formDataComercio.comercioDireccion }
                                            onChange={ handleChangeComercio }
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="comercioTelefono">Teléfono</label>
                                        <input
                                            className="form-control"
                                            id="comercioTelefono"
                                            name="comercioTelefono"
                                            type="number"
                                            placeholder="Teléfono"
                                            value={ formDataComercio.comercioTelefono }
                                            onChange={ handleChangeComercio }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="comercioEmail">Email</label>
                                        <input
                                            className="form-control" 
                                            id="comercioEmail"
                                            name="comercioEmail"
                                            type="email"
                                            placeholder="Email"
                                            value={ formDataComercio.comercioEmail }
                                            onChange={ handleChangeComercio }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <hr />

                            {/* Datos del usuario */}

                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                <h4 className="modal-title" id="myLargeModalLabelComercio">
                                    Datos Usuario
                                </h4>
                                </div>
                            </div>

                            <hr /><br />

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="usuarioNombres">Nombre</label>
                                        <input
                                            className="form-control"
                                            id="usuarioNombres"
                                            name="usuarioNombres"
                                            type="text"
                                            placeholder="Nombre"
                                            value={ formDataUsuario.usuarioNombres }
                                            onChange={ handleChangeUsuario }
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="usuarioApellidos">Apellidos</label>
                                        <input
                                            className="form-control" 
                                            id="usuarioApellidos"
                                            name="usuarioApellidos"
                                            type="text"
                                            placeholder="Apellidos"
                                            value={ formDataUsuario.usuarioApellidos }
                                            onChange={ handleChangeUsuario }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="usuarioDireccion">Direccion</label>
                                        <input
                                            className="form-control" 
                                            id="usuarioDireccion"
                                            name="usuarioDireccion"
                                            type="text"
                                            placeholder="Direccion"
                                            value={ formDataUsuario.usuarioDireccion }
                                            onChange={ handleChangeUsuario }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="usuarioDocumento">Documento</label>
                                        <input
                                            className="form-control" 
                                            id="usuarioDocumento"
                                            name="usuarioDocumento"
                                            type="number"
                                            placeholder="Documento"
                                            value={ formDataUsuario.usuarioDocumento }
                                            onChange={ handleChangeUsuario }
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="usuarioCelular">Celular</label>
                                        <input
                                            className="form-control" 
                                            id="usuarioCelular"
                                            name="usuarioCelular"
                                            type="number"
                                            placeholder="Celular"
                                            value={ formDataUsuario.usuarioCelular }
                                            onChange={ handleChangeUsuario }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="usuarioCorreoElectronico">Email</label>
                                        <input
                                            className="form-control" 
                                            id="usuarioCorreoElectronico"
                                            name="usuarioCorreoElectronico"
                                            type="email"
                                            placeholder="Email"
                                            value={ formDataUsuario.usuarioCorreoElectronico }
                                            onChange={ handleChangeUsuario }
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="usuarioContrasenia">Contraseña</label>
                                        <input
                                            className="form-control"
                                            id="usuarioContrasenia"
                                            name="usuarioContrasenia"
                                            type="password"
                                            placeholder="Contraseña"
                                            value={ formDataUsuario.usuarioContrasenia }
                                            onChange={ handleChangeUsuario }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Cancelar
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Crear
                            </button>
                        </div>
                    
                    </div>
                </div>
            </div>
        </form>
    )
}

export default ModalNuevoComercio
