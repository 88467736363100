import ModalNuevoCredito from '../Credito/ModalNuevoCredito';
import Spinner from '../Spinner';
import Credito from './Credito';

const DatosCredito = ({ cliente, loading }) => {
    return (
        <>
            <div className="row mb-20">
                <div className="col-md-6">
                    <div className="clearfix">
                        <div className="pull-left">
                            <h4 className="text-blue h4">Créditos</h4>
                            {/* <p className="mb-30">Cargar información de créditos</p> */}
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <button
                        type="button"
                        className="btn btn-block btn-outline-success"
                        data-backdrop="static"
                        data-toggle="modal"
                        data-target="#modalNuevoCredito"
                        disabled={ cliente.clienteId === '' || cliente.clienteCupoAprobado === 0 }
                    >
                        Nuevo Credito
                    </button>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 col-sm-12 mb-30">
                    <hr />
                    <h5 className="h4 text-blue">Datos de Creditos</h5>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 col-sm-12 mb-30">
                { loading ? 
                    <div className="row text-center">
                        <div className="col-md-12">
                            <Spinner />
                        </div>
                    </div>

                  :

                    <div className="tab">
                        <ul className="nav nav-tabs customtab" role="tablist">
                            <li className="nav-item">
                                <a
                                    className="nav-link active"
                                    data-toggle="tab"
                                    href="#creditosPendientes"
                                    role="tab"
                                    aria-selected="true"
                                >
                                    Pendientes ({ cliente.clienteCreditosPendientes && cliente.clienteCreditosPendientes.length ? cliente.clienteCreditosPendientes.length : 0 })
                                </a>
                            </li>

                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href="#creditosVigentes"
                                    role="tab"
                                    aria-selected="true"
                                >
                                    Vigentes ({ cliente.clienteCreditosVigentes && cliente.clienteCreditosVigentes.length ? cliente.clienteCreditosVigentes.length : 0 })
                                </a>
                            </li>

                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href="#creditosRetrasados"
                                    role="tab"
                                    aria-selected="true"
                                >
                                    Retrasados ({ cliente.clienteCreditosRetrasados && cliente.clienteCreditosRetrasados.length ? cliente.clienteCreditosRetrasados.length : 0 })
                                </a>
                            </li>

                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href="#creditosHistorico"
                                    role="tab"
                                    aria-selected="false"
                                >
                                    Pagados ({ cliente.clienteCreditosHistoricos && cliente.clienteCreditosHistoricos.length ? cliente.clienteCreditosHistoricos.length : 0 })
                                </a>
                            </li>
                        </ul>

                        <div className="tab-content">
                            
                            <div
                                className="tab-pane fade show active"
                                id="creditosPendientes"
                                role="tabpanel"
                            >
                                <div className="pd-10 faq-wrap">
                                    <div id="accordion2">
                                        {
                                            cliente.clienteId !== '' ?
                                            cliente.clienteCreditosPendientes && cliente.clienteCreditosPendientes.length > 0 ?
                                                    cliente.clienteCreditosPendientes.map((creditoPend) => (
                                                        <Credito
                                                            key={ creditoPend.credito_id }
                                                            creditoTipo={'pendiente'}
                                                            credito={ creditoPend }
                                                            dataParentElem={ '#accordion2' }
                                                        />
                                                    ))
                                                :
                                                    <b> No hay créditos pendientes para mostrar </b>
                                            :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div
                                className="tab-pane fade"
                                id="creditosVigentes"
                                role="tabpanel"
                            >
                                <div className="pd-10 faq-wrap">
                                    <div id="accordion1">
                                        {
                                            cliente.clienteId !== '' ?
                                            cliente.clienteCreditosVigentes && cliente.clienteCreditosVigentes.length > 0 ?
                                                    cliente.clienteCreditosVigentes.map((creditoVig) => (
                                                        <Credito
                                                            key={ creditoVig.credito_id }
                                                            creditoTipo={'vigente'}
                                                            credito={ creditoVig }
                                                            dataParentElem={ '#accordion1' }
                                                        />
                                                    ))
                                                :
                                                    <b> No hay creditos vigentes que mostrar </b>
                                            :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div
                                className="tab-pane fade"
                                id="creditosRetrasados"
                                role="tabpanel"
                            >
                                <div className="pd-10 faq-wrap">
                                    <div id="accordion4">
                                        {
                                            cliente.clienteId !== '' ?
                                            cliente.clienteCreditosRetrasados && cliente.clienteCreditosRetrasados.length > 0 ?
                                                    cliente.clienteCreditosRetrasados.map((creditoRet) => (
                                                        <Credito
                                                            key={ creditoRet.credito_id }
                                                            creditoTipo={'retrasado'}
                                                            credito={ creditoRet }
                                                            dataParentElem={ '#accordion4' }
                                                        />
                                                    ))
                                                :
                                                    <b> No hay creditos vigentes que mostrar </b>
                                            :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div 
                                className="tab-pane fade" 
                                id="creditosHistorico" 
                                role="tabpanel"
                            >
                                <div className="pd-10 faq-wrap">
                                    <div id="accordion3">
                                        {
                                            cliente.clienteId !== '' ?
                                            cliente.clienteCreditosHistoricos && cliente.clienteCreditosHistoricos.length > 0 ?
                                                    cliente.clienteCreditosHistoricos.map((creditoHisto) => (
                                                        <Credito
                                                            key={ creditoHisto.credito_id }
                                                            creditoTipo={'historico'}
                                                            credito={ creditoHisto }
                                                            dataParentElem={ '#accordion3' }
                                                        />
                                                    ))
                                                :
                                                    <b> No hay creditos pagados que mostrar </b>
                                            :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                </div>
            </div>

            {/* Inicio Modal Nuevo Credito */}
            <ModalNuevoCredito clienteId={ cliente.clienteId } />
            {/* Fin Modal Nuevo Credito */}
        </>
    )
}

export default DatosCredito