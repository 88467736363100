import React, {useEffect} from 'react';
import $ from 'jquery';

const Preloader = () => {
    useEffect(() => {
        var 
        // width = 100,
        perfData = window.performance.timing, // The PerformanceTiming interface represents timing-related performance information for the given page.
        EstimatedTime = -(perfData.loadEventEnd - perfData.navigationStart),
        time = parseInt((EstimatedTime/1000)%60)*100;

        // Percentage Increment Animation
        var PercentageID = $("#percentLoad"),
            start = 0,
            end = 100,
            durataion = time;
            animateValue(PercentageID, start, end, durataion);
            
        function animateValue(id, start, end, duration) {
            var range = end - start,
            current = start,
            increment = end > start? 1 : -1,
            stepTime = Math.abs(Math.floor(duration / range)),
            obj = $(id);

            var timer = setInterval(function() {
                current += increment;
                $(obj).text(current + "%");
                $("#barLoad").css('width', current+"%");
            //obj.innerHTML = current;
                if (current === end) {
                    clearInterval(timer);
                }
            }, stepTime);
        }

        // Fading Out Loadbar on Finised
        setTimeout(function() {
            $('.pre-loader').fadeOut(300);
        }, time);
    }, []);

    return (
        <>
            <div className="pre-loader">
                <div className="pre-loader-box">
                    <div className="loader-logo">
                        <img
                            src={ process.env.PUBLIC_URL + "/logo-creditxtatts.png" }
                            alt="creditxtatts" 
                        />
                    </div>
                    <div className="loader-progress" id="progress_div">
                        <div className="bar" id="barLoad"></div>
                    </div>
                    <div className="percent" id="percentLoad">0%</div>
                    <div className="loading-text">Cargando...</div>
                </div>
            </div>
        </>
    )
}

export default Preloader