import axios from 'axios';
import Swal  from 'sweetalert2';
import { 
    // convertirACOP, 
    getAPIBaseUrl } from '../utils';
import BootstrapSelectPicker from '../SelectPicker';
import {
    useContext, 
    // useEffect, 
    useState
} from 'react';
import Spinner from '../Spinner';
import {UserContext} from '../../context/UserContext';


const ModalNuevoCliente = ({ clienteObj, cliente, setCliente, handleChange }) => {
    const [loading, setLoading] = useState(false);
    // const [loadingCupoAprobado, setLoadingCupoAprobado] = useState(false);
    const {user} = useContext(UserContext);

    const handleSubmitCrearCliente = async (evt) => {
        evt.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post( getAPIBaseUrl()+`clientes/nuevo`, cliente );
            console.log( 'response:', response.data);

            if(!response.data.success) {
                setLoading(false);

                Swal.fire({
                    icon: 'error',
                    title: 'Error cliente',
                    text: response.data.message,
                    // footer: '<a href="">Why do I have this issue?</a>'
                });
                return;
            } else {
                setLoading(false);

                Swal.fire({
                    title: 'Cliente Creado!',
                    text: 'Correctamente!',
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    // showCancelButton: true,
                    confirmButtonClass: 'btn btn-success',
                    // cancelButtonClass: 'btn btn-danger'
                }).then((result) => {
                    // console.log('result:', result);
                    if(result.isConfirmed) {

                        // setCliente(clienteObj);

                        setCliente({
                            ...cliente,
                            clienteDocumentoBuscar: response.data.clienteNumeroDocumento
                        });

                        // const clienteDocumentoBuscar = document.querySelector("#clienteDocumentoBuscar");
                        // clienteDocumentoBuscar.value = response.data.clienteNumeroDocumento;

                        const buscarCliente = document.querySelector("#submitBuscarCliente");
                        buscarCliente.click();

                        const modalClose = document.querySelector("#modalNuevoCliente .close");
                        modalClose.click();
                    }
                });
            }
        } catch (error) {
            setLoading(false);

            Swal.fire({
                icon: 'error',
                title: 'Error interno cliente',
                text: error,
                // footer: '<a href="">Why do I have this issue?</a>'
            });
        }
    }

    // const getCupoDatacredito = async (e) => {
    //     const documento = e.target.value;
    //     // console.log('documento:', documento);

    //     setLoadingCupoAprobado(true);

    //     const respDatacredito =  await axios.get( getAPIBaseUrl()+`clientes/getcupodatacredito/${documento}`);
    //     console.log('respDatacredito2:', respDatacredito);

    //     if(!respDatacredito.data.success) {
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Error obteniendo el cupo del cliente',
    //             text: respDatacredito.data.message,
    //             // footer: '<a href="">Why do I have this issue?</a>'
    //         });

    //         setLoadingCupoAprobado(false);
    //     } else {
    //         const cupoDatacredito = JSON.parse(respDatacredito.data.message).data.availableCreditLimit;
    //         console.log('cupoDatacredito:', convertirACOP(cupoDatacredito));

    //         setCliente({
    //             ...cliente,
    //             clienteCupoAprobado: cupoDatacredito
    //         });

    //         setLoadingCupoAprobado(false);
    //     }
    // }

    return (
        <form onSubmit={ handleSubmitCrearCliente }>
            <div
                className="modal fade bs-example-modal-lg"
                id="modalNuevoCliente"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myLargeModalLabel">
                                Nuevo Cliente
                            </h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-hidden="true"
                            >
                                ×
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="clienteNombre">Nombre</label>
                                        <input
                                            className="form-control"
                                            id="clienteNombre"
                                            name="clienteNombre"
                                            type="text"
                                            placeholder="Nombre"
                                            value={ cliente.clienteNombre }
                                            onChange={ handleChange }
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="clienteApellidos">Apellidos</label>
                                        <input
                                            className="form-control"
                                            id="clienteApellidos"
                                            name="clienteApellidos"
                                            type="text" 
                                            placeholder="Apellidos"
                                            value={ cliente.clienteApellidos }
                                            onChange={ handleChange }
                                            required 
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="clienteTipoDocumento">Tipo Documento</label>
                                        <BootstrapSelectPicker
                                            idNameSelect={'clienteTipoDocumento'}
                                            selectValue={cliente.clienteTipoDocumento}
                                            handleChange={handleChange}
                                            options={[
                                                { value: 'CC', name: 'Cédula de ciudadanía' },
                                                { value: 'CE', name: 'Cédula de extranjería' },
                                                { value: 'DIE', name: 'Documento de identificación extranjero' },
                                                { value: 'NIT', name: '(Nit) Número de Identificación Tributaria (Empresas)' },
                                                { value: 'P', name: 'Pasaporte' },
                                                { value: 'RCN', name: 'Registro civil de nacimiento' },
                                                { value: 'TI', name: 'Tarjeta de identidad' },
                                            ]}
                                            required={true}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="clienteNumeroDocumento">Número de documento</label>
                                        <input
                                            className="form-control" 
                                            id="clienteNumeroDocumento"
                                            name="clienteNumeroDocumento"
                                            type="number"
                                            placeholder="Número de documento"
                                            value={ cliente.clienteNumeroDocumento }
                                            onChange={ handleChange }
                                            // onBlur={ getCupoDatacredito }
                                            required 
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="clienteCupoAprobado">Cupo aprobado</label>
                                        <br />
                                        {/* { loadingCupoAprobado ?
                                            <Spinner sm={true}/>
                                          :
                                            <>
                                                { convertirACOP(cliente.clienteCupoAprobado) }
                                            </>
                                        } */}
                                        
                                        {
                                            // loadingCupoAprobado ?
                                            //     <Spinner sm={true}/>
                                            // :
                                                <>
                                                    { 
                                                        user && (user[0].usuario_rol === 'admin') ?
                                                            <div className="input-group mb-3">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text">$</span>
                                                                </div>
                                                                <input
                                                                    className="form-control"
                                                                    id="clienteCupoAprobado"
                                                                    name="clienteCupoAprobado"
                                                                    type="number"
                                                                    placeholder="Cupo aprobado"
                                                                    value={ cliente.clienteCupoAprobado }
                                                                    onChange={ handleChange }
                                                                    required
                                                                />
                                                            </div>
                                                        :
                                                            <>
                                                                {/* { convertirACOP(cliente.clienteCupoAprobado) } */}

                                                                <small className="has-danger">
                                                                    Para solicitar el cupo debe comunicarse con un asesor.
                                                                </small>
                                                            </>
                                                    }
                                                </>
                                        }
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="clienteDireccion">Dirección</label>
                                        <input
                                            className="form-control" 
                                            id="clienteDireccion"
                                            name="clienteDireccion"
                                            type="text" 
                                            placeholder="Dirección" 
                                            value={ cliente.clienteDireccion }
                                            onChange={ handleChange }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="clienteCorreoElectronico">Correo electrónico</label>
                                        <input
                                            className="form-control" 
                                            id="clienteCorreoElectronico"
                                            name="clienteCorreoElectronico"
                                            type="email"
                                            placeholder="Correo electrónico"
                                            value={ cliente.clienteCorreoElectronico }
                                            onChange={ handleChange }
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="clienteCelular">Celular</label>
                                        <input
                                            className="form-control" 
                                            id="clienteCelular"
                                            name="clienteCelular"
                                            type="tel" 
                                            placeholder="Celular"
                                            value={ cliente.clienteCelular }
                                            onChange={ handleChange }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <hr />

                            {/* referencia personal */}

                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                <h4 className="modal-title" id="myLargeModalLabelComercio">
                                    Referencia Personal
                                </h4>
                                </div>
                            </div>

                            <hr />

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="refPersNombre">Nombre</label>
                                        <input
                                            className="form-control"
                                            id="refPersNombre"
                                            name="refPersNombre"
                                            type="text"
                                            placeholder="Nombre"
                                            value={ cliente.refPersNombre }
                                            onChange={ handleChange }
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="refPersCelular">Celular</label>
                                        <input
                                            className="form-control" 
                                            id="refPersCelular"
                                            name="refPersCelular"
                                            type="number"
                                            placeholder="Celular"
                                            value={ cliente.refPersCelular }
                                            onChange={ handleChange }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <hr />

                            {/* referencia laboral */}

                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                <h4 className="modal-title" id="myLargeModalLabelComercio">
                                    Referencia Laboral
                                </h4>
                                </div>
                            </div>

                            <hr />

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="refLabNombre">Nombre</label>
                                        <input
                                            className="form-control"
                                            id="refLabNombre"
                                            name="refLabNombre"
                                            type="text"
                                            placeholder="Nombre"
                                            value={ cliente.refLabNombre }
                                            onChange={ handleChange }
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="refLabCelular">Celular</label>
                                        <input
                                            className="form-control" 
                                            id="refLabCelular"
                                            name="refLabCelular"
                                            type="number"
                                            placeholder="Celular"
                                            value={ cliente.refLabCelular }
                                            onChange={ handleChange }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Cancelar
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={ loading }
                            >
                                { loading ? <Spinner sm={true}/> : <></> }
                                Crear
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default ModalNuevoCliente