import {useContext} from 'react'
import {UserContext} from '../../context/UserContext'
import { NavLink } from 'react-router-dom';
import { toggleLeftMenu } from '../utils';

const LeftSidebar = (props) => {
    const {user, logout} = useContext(UserContext);

    return (
        <>
            <div className="left-side-bar">
                <div className="brand-logo" style={{ 'border': 'none'}}>
                    {/* <a href="index.html"> */}
                    <NavLink 
                        to={`clientes`}
                    >
                        <img
                            // src={ process.env.PUBLIC_URL + "/theme/vendors/images/deskapp-logo.svg" }
                            src={ process.env.PUBLIC_URL + "/logo-creditxtatts.png" }
                            // src={ process.env.PUBLIC_URL + "/logo-creditxtatts.jpeg" }
                            alt=""
                            className="dark-logo"
                        />
                        <img
                            // src={ process.env.PUBLIC_URL + "/theme/vendors/images/deskapp-logo-white.svg" }
                            src={ process.env.PUBLIC_URL + "/logo-creditxtatts.png" }
                            // src={ process.env.PUBLIC_URL + "/logo-creditxtatts.jpeg" }
                            alt=""
                            className="light-logo"
                        />
                    {/* </a> */}
                    </NavLink>

                    <div 
                        className="close-sidebar" 
                        data-toggle="left-sidebar-close" 
                        onClick={toggleLeftMenu}
                    >
                        <i className="ion-close-round"></i>
                    </div>
                </div>

                <div className="menu-block customscroll">
                    <div className="sidebar-menu icon-style-3 icon-list-style-6">
                        <ul id="accordion-menu">
                            { user[0].usuario_rol === 'admincom' ?
                                <li>
                                    <NavLink 
                                        to={`clientes`}
                                        className="dropdown-toggle no-arrow"
                                    >
                                        <i className="micon bi bi-people"></i>
                                        <span className="mtext">Clientes</span>
                                    </NavLink>
                                </li>
                              :
                                <></>
                            } 

                            { user[0].usuario_rol === 'admin' ?
                                <li>
                                    <NavLink 
                                        to={`comercios`} 
                                        className="dropdown-toggle no-arrow"
                                    >
                                        <i className="micon bi bi-cart2"></i>
                                        <span className="mtext">Comercios</span>
                                    </NavLink>
                                </li>
                              :
                                <></>
                            }

                            { user[0].usuario_rol === 'admin' ?
                                <li>
                                    <NavLink 
                                        to={`clientescupo`} 
                                        className="dropdown-toggle no-arrow"
                                    >
                                        <i className="micon bi bi-person-check"></i>
                                        <span className="mtext">Clientes</span>
                                    </NavLink>
                                </li>
                              :
                                <></>
                            }

                            { user[0].usuario_rol === 'admin' ?
                                <li>
                                    <NavLink
                                        to={`creditosaprobar`}
                                        className="dropdown-toggle no-arrow"
                                    >
                                        <i className="micon bi bi-person-lines-fill"></i>
                                        <span className="mtext">Creditos</span>
                                    </NavLink>
                                </li>
                              :
                                <></>
                            }

                            {/* <li>
                                <NavLink to={`usuarios`} className="dropdown-toggle no-arrow">
                                    <i className="micon bi bi-person-lines-fill"></i>
                                    <span className="mtext">Usuarios</span>
                                </NavLink>
                            </li> */}

                            <li>
                                <NavLink 
                                    to={`simulador`} 
                                    className="dropdown-toggle no-arrow"
                                >
                                    <i className="micon bi bi-calculator"></i>
                                    <span className="mtext">Simulador</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink 
                                    to={`reportes`} 
                                    className="dropdown-toggle no-arrow"
                                >
                                    {/* <i className="micon bi bi-people"></i> */}
                                    <i className="micon bi bi-card-list"></i>
                                    <span className="mtext">Reportes</span>
                                </NavLink>
                            </li>

                            {/* <li>
                                <NavLink to={`configuracion`} className="dropdown-toggle no-arrow">
                                    <i className="micon bi bi-gear"></i>
                                    <span className="mtext">Configuración</span>
                                </NavLink>
                            </li> */}

                            <li>
                                <div className="dropdown-divider"></div>
                            </li>

                            <li>
                                <div className="sidebar-small-cap">
                                    <i className="bi bi-person-badge"></i>
                                    {user[0].usuario_nombres} <br /> {user[0].usuario_apellidos}
                                </div>
                            </li>

                            <li>
                                <NavLink 
                                    to={`perfil`} 
                                    className="dropdown-toggle no-arrow"
                                >
                                    <i className="micon bi bi-person-circle"></i>
                                    <span className="mtext">Perfil</span>
                                </NavLink>
                            </li>

                            <li>
                                <NavLink 
                                    to={`/`}
                                    className="dropdown-toggle no-arrow"
                                    onClick={logout}
                                >
                                    <i className="micon bi bi-box-arrow-right"></i>
                                    <span className="mtext">Salir</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="mobile-menu-overlay"></div>
        </>
    )
}

export default LeftSidebar