// import {useEffect} from "react";
// import {selectpicker} from 'bootstrap-select';
// import $ from 'jquery';

const Perfil = () => {
    const handleSubmitEditarPerfil = async (evt) => {
        evt.preventDefault();

        console.log('Editar perfil');
    }

    // useEffect(() => {
    //     // document.querySelector('.selectpicker').selectpicker();
    //     // $('.selectpicker').selectpicker();
    // }, []);

    return (
        <>
            <div className="pd-20 card-box mb-30">
                <div className="clearfix">
                    <div className="pull-left">
                        <h4 className="text-blue h4">Editar Perfíl</h4>
                        {/* <p className="mb-30">Editar perfil del usuario</p> */}
                    </div>
                </div>

                <br />

                <form onSubmit={ handleSubmitEditarPerfil }>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group"> 
                                <label htmlFor="usuario-nombre">Nombre</label>
                                <input
                                    className="form-control" 
                                    id="usuario-nombre"
                                    name="usuario-nombre"
                                    type="text" 
                                    placeholder="Nombre"
                                    required
                                />
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                            <div className="form-group"> 
                                <label htmlFor="usuario-documento">Apellidos</label>
                                <input
                                    className="form-control" 
                                    id="usuario-documento"
                                    name="usuario-documento"
                                    type="text" 
                                    placeholder="Apellidos"
                                    required 
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group"> 
                                <label htmlFor="usuario-tipo-documento">Tipo Documento</label>
                                <select
                                    className="selectpicker form-control"
                                    id="usuario-tipo-documento"
                                    name="usuario-tipo-documento"
                                    data-size="5"
                                    required
                                >
                                    <option value={''}>- Selecione -</option>
                                    
                                    {/* <optgroup label="Plazo" data-max-options="2"> */}
                                        <option value={'s'}>Cédula de ciudadanía</option>
                                        <option value={'q'}>Cédula de extranjería</option>
                                        <option value={'q'}>Documento de identificación extranjero</option>
                                        <option value={'q'}>(Nit) Número de Identificación Tributaria (Empresas)</option>
                                        <option value={'q'}>Pasaporte</option>
                                        <option value={'q'}>Registro civil de nacimiento</option>
                                        <option value={'q'}>Tarjeta de identidad</option>
                                    {/* </optgroup> */}
                                </select>
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                            <div className="form-group"> 
                                <label htmlFor="usuario-numero-documento">Número de documento</label>
                                <input
                                    className="form-control" 
                                    id="usuario-numero-documento"
                                    name="usuario-numero-documento"
                                    type="number"
                                    placeholder="Número de documento"
                                    required 
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group"> 
                                <label htmlFor="usuario-direccion">Dirección</label>
                                <input
                                    className="form-control" 
                                    id="usuario-direccion"
                                    name="usuario-direccion"
                                    type="text" 
                                    placeholder="Dirección" 
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group"> 
                                <label htmlFor="usuario-correo-electronico">Correo electrónico</label>
                                <input
                                    className="form-control" 
                                    id="usuario-correo-electronico"
                                    name="usuario-correo-electronico"
                                    type="email"
                                    placeholder="Correo electrónico"
                                    required
                                />
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                            <div className="form-group"> 
                                <label htmlFor="usuario-celular">Celular</label>
                                <input
                                    className="form-control" 
                                    id="usuario-celular"
                                    name="usuario-celular"
                                    type="tel" 
                                    placeholder="Celular"
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group"> 
                                <label htmlFor="usuario-contrasenia">Contraseña</label>
                                <input
                                    className="form-control" 
                                    id="usuario-contrasenia"
                                    name="usuario-contrasenia"
                                    type="password" 
                                    placeholder="Contraseña" 
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <button type="submit" className="btn btn-block btn-primary">
                        Guardar
                    </button>
                </form>
            </div>
        </>
    )
}

export default Perfil