import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import UserContextProvider from './context/UserContext.js';

// // Import styles
// import './theme/src/styles/media.css';
// import './theme/src/styles/style.css';
// import './theme/src/styles/theme.css';

// import './theme/vendors/styles/core.css';
// import './theme/vendors/styles/style.css';

import './styles/index.css';

import './Fixtures.js';
import App from './App.js';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render (
  <React.StrictMode>
    <UserContextProvider>
      <App />
    </UserContextProvider>
  </React.StrictMode>
);