import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

import ModalNuevoCliente from "./ModalNuevoCliente";
import EditarCliente from "./EditarCliente";

import { convertirACOP, getAPIBaseUrl, filterCreditos } from "../utils";
import Spinner from "../Spinner";

const DatosCliente = ({
  clienteObj,
  cliente,
  setCliente,
  handleChange,
  loading,
  setLoading,
}) => {
  const [datosCliente, setDatosCliente] = useState({});

  const handleChangeDatosCliente = (evt) => {
    /**
     * evt.target es el elemento que ejecuto el evento
     * name identifica el input y value describe el valor actual
     */
    const { target } = evt;
    const { name, value } = target;

    /**
     * Este snippet:
     * 1. Clona el estado actual
     * 2. Reemplaza solo el valor del input que ejecutó el evento
     */
    const newCliente = {
      ...datosCliente,
      [name]: value,
    };

    // console.log('newClienteBusc:', newCliente);

    // Sincroniza el estado de nuevo
    setCliente(newCliente);
  };

  const handleSubmitBuscarCliente = async (evt) => {
    evt.preventDefault();

    setLoading(true);

    try {
      const response = await axios.get(
        `${getAPIBaseUrl()}clientes/bydocument/${
          cliente.clienteDocumentoBuscar
        }`
      );
      // console.log('response:', response.data[0]);

      if (response.data[0]) {
        const clienteData = response.data[0];
        // console.log( 'clienteData:', clienteData );
        // console.log('clienteData.cliente_id:', clienteData.cliente_id);

        const getCreditosByClienteId = await axios.get(
            `${getAPIBaseUrl()}creditosbyclienteid/${clienteData.cliente_id}`
        );
        let creditosData = getCreditosByClienteId.data;
        // console.log('creditosData.success:', creditosData.success);
        // console.log('creditosData.message:', creditosData.message);

        let creditosPendientes, creditosVigentes, creditosRetrasados, creditosPagados = 0;
        if(creditosData.success && creditosData.message !== '')
        {
          const creditos = creditosData.message;
          // console.log("creditos:", creditos);
          creditosPendientes = filterCreditos(creditos, "pendiente");
          creditosVigentes = filterCreditos(creditos, "vigente");
          creditosRetrasados = filterCreditos(creditos, "retrasado");
          creditosPagados = filterCreditos(creditos, "pagado");
        }

        const getfotoultimocredito = await axios.get(
          `${getAPIBaseUrl()}creditos/getfotoultimocredito/byidcliente/${
            clienteData.cliente_id
          }`
        );
        // console.log('getfotoultimocredito:', getfotoultimocredito.data);

        const getCreditosCupoByid = await axios.get(
          `${getAPIBaseUrl()}clientes/creditoscupobyid/${
            clienteData.cliente_id
          }`
        );
        // console.log('getCreditosCupoByid:', getCreditosCupoByid.data.message);

        setDatosCliente({
          clienteMostrarInfo: true,
          clienteFoto: getfotoultimocredito.data.credito_fotografia_cliente,
          clienteNombre: clienteData.cliente_nombre,
          clienteApellidos: clienteData.cliente_apellidos,
          clienteTipoDocumento: clienteData.cliente_tipo_documento,
          clienteDocumento: clienteData.cliente_documento,
          clienteDireccion: clienteData.cliente_direccion,
          clienteCupoAprobado: clienteData.cliente_cupo_aprobado,
          clienteCupoDisponible: getCreditosCupoByid.data.message.cupo_disponible,
          clienteTotAdeudadoCreditos: getCreditosCupoByid.data.message.tot_adeudado_creditos,
        });

        setCliente({
          ...cliente,
          clienteFoto: getfotoultimocredito.data.credito_fotografia_cliente,
          clienteId: clienteData.cliente_id,
          clienteCupoAprobado: clienteData.cliente_cupo_aprobado,
          clienteCreditosPendientes: creditosPendientes,
          clienteCreditosVigentes: creditosVigentes,
          clienteCreditosRetrasados: creditosRetrasados,
          clienteCreditosHistoricos: creditosPagados
        });

        setLoading(false);
      } else {
        Swal.fire({
          title: "No hay cliente que mostrar con este documento!",
          text: "Desea crearlo?",
          icon: "info",
          confirmButtonText: "Crear Cliente",
          cancelButtonText: "Cancelar",
          showCancelButton: true,
          confirmButtonClass: "btn btn-info",
          cancelButtonClass: "btn btn-danger",
        }).then((result) => {
          // console.log('result:', result);
          if (result.isConfirmed) {
            document.getElementById("btnNuevoCliente").click();

            setCliente({
              ...cliente,
              clienteNumeroDocumento: cliente.clienteDocumentoBuscar,
              clienteCupoAprobado: 0,
              clienteCreditosPendientes: [],
              clienteCreditosVigentes: [],
              clienteCreditosRetrasados: [],
              clienteCreditosHistoricos: [],
            });
          }
        });

        setLoading(false);
      }
    } catch (error) {
      console.error("Error buscando cliente:", error);
    }
  };

  const limpiarDatosCliente = (evt) => {
    if (evt.target.value === "") {
      // console.log('Limpiar datos busqueda');
      setDatosCliente({});
      setCliente({
        ...cliente,
        clienteFoto: "",
        clienteId: "",
        // clienteCupoAprobado: 0,
        clienteCreditosPendientes: "",
        clienteCreditosVigentes: "",
        clienteCreditosHistoricos: "",
      });
    }
  };

  return (
    <>
      <div className="pd-20 card-box mb-30">
        <div className="row mb-20">
          <div className="col-md-6">
            <div className="clearfix">
              <div className="pull-left">
                <h4 className="text-blue h4">Clientes</h4>
                {/* <p className="mb-30">Cargar información de un cliente</p> */}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <button
              type="button"
              id="btnNuevoCliente"
              className="btn btn-block btn-outline-success"
              data-backdrop="static"
              data-toggle="modal"
              data-target="#modalNuevoCliente"
            >
              Nuevo Cliente
            </button>
          </div>
        </div>

        {/* Inicio Form Buscar Cliente */}
        <form id="frmBuscarCliente" onSubmit={handleSubmitBuscarCliente}>
          <div className="form-group">
            <label htmlFor="clienteDocumentoBuscar">Documento a buscar</label>
            <input
              className="form-control"
              id="clienteDocumentoBuscar"
              name="clienteDocumentoBuscar"
              type="number"
              placeholder="Documento"
              value={cliente.clienteDocumentoBuscar}
              onChange={handleChange}
              onKeyUp={limpiarDatosCliente}
              required
            />
          </div>
          <button
            type="submit"
            id="submitBuscarCliente"
            className="btn btn-block btn-primary"
          >
            {loading ? <Spinner sm={true} /> : <></>}
            Buscar
          </button>
        </form>
        {/* Fin Form Buscar Cliente */}
      </div>

      <div
        id="datosCliente"
        className="pd-20 card-box mb-30"
        style={{
          display:
            datosCliente.clienteMostrarInfo &&
            cliente.clienteDocumentoBuscar !== ""
              ? ""
              : "none",
        }}
      >
        {/* Inicio datos cliente */}
        <div>
          {/* <div id="datosCliente"> */}
          <div className="row">
            <div className="col-md-8 col-sm-8">
              <h5 className="h4 text-blue">Datos del Cliente</h5>
            </div>
            <div className="col-md-4 col-sm-4 text-center">
              <img
                src={
                  cliente.clienteFoto
                    ? cliente.clienteFoto
                    : process.env.PUBLIC_URL + "/logo-creditxtatts.png"
                }
                className="img-fluid rounded-circle"
                alt="Foto Cliente"
                style={{ width: "100px", height: "100px" }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="tab">
                <ul className="nav nav-tabs customtab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#clienteResumen"
                      role="tab"
                      aria-selected="false"
                    >
                      Cliente
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#clienteEditar"
                      role="tab"
                      aria-selected="false"
                    >
                      Editar
                    </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="clienteResumen"
                    role="tabpanel"
                  >
                    <div className="pd-10">
                      <p>
                        {" "}
                        <b>Nombre: </b> {datosCliente.clienteNombre}{" "}
                      </p>
                      <p>
                        {" "}
                        <b>{datosCliente.clienteTipoDocumento}: </b>{" "}
                        {datosCliente.clienteDocumento}{" "}
                      </p>
                      <p>
                        {" "}
                        <b>Dirección: </b> {datosCliente.clienteDireccion}{" "}
                      </p>
                      <p>
                        {" "}
                        <b>Cupo aprobado: </b>{" "}
                        {convertirACOP(datosCliente.clienteCupoAprobado)}{" "}
                      </p>
                      <p>
                        {" "}
                        <b>Cupo disponible: </b>{" "}
                        {convertirACOP(datosCliente.clienteCupoDisponible)}{" "}
                      </p>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="clienteEditar"
                    role="tabpanel"
                  >
                    <div className="pd-10">
                      <EditarCliente
                        datosCliente={datosCliente}
                        clienteObj={clienteObj}
                        cliente={cliente}
                        setCliente={setCliente}
                        handleChange={handleChange}
                        handleChangeDatosCliente={handleChangeDatosCliente}
                        baseUrl={getAPIBaseUrl}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Fin datos cliente */}
      </div>

      {/* Inicio Modal Nuevo Cliente */}
      <ModalNuevoCliente
        clienteObj={clienteObj}
        cliente={cliente}
        setCliente={setCliente}
        handleChange={handleChange}
      />
      {/* Fin Modal Nuevo Cliente */}
    </>
  );
};

export default DatosCliente;
