import {useState} from "react";
import Cuota from "./Cuota";
import ComprobantePago from "./ComprobantePago.js";

const  Cuotas = ({ creditoCuotas, getCreditos }) => {
    const [datosPrint, setDatosPrint] = useState({});

    return (
        <div className="table-responsive">
            <table className="table table-sm table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        {/* <th scope="col" style={{ 'width': '20px !important' }}>Fecha Vence</th> */}
                        <th scope="col" width="50">Fecha Vence</th>
                        <th scope="col">Capital</th>
                        <th scope="col">Interés</th>
                        <th scope="col">Aval</th>
                        <th scope="col">Días mora</th>
                        <th scope="col">Interés mora</th>
                        <th scope="col">Total</th>
                        <th scope="col">Estado</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        creditoCuotas && creditoCuotas.length > 0 ?
                            creditoCuotas.map(cuota => (
                                <Cuota
                                    key={cuota.cuota_id}
                                    cuota={cuota}
                                    getCreditos={getCreditos}
                                    setDatosPrint={setDatosPrint}
                                />
                            ))
                        :
                            <></>
                    }
                </tbody>
            </table>

            {
                Object.keys(datosPrint).length > 0 ?
                    <div style={{ display: "none" }}>
                        <ComprobantePago
                            datosPrint={datosPrint}
                        />
                    </div>
                :
                    <></>
            }
        </div>
    )
}

export default Cuotas