import {useContext, useState } from "react";
import { convertirACOP, getAPIBaseUrl } from "../utils";
import Swal from "sweetalert2";
import axios from "axios";
import {UserContext} from "../../context/UserContext.js";
import printJS from 'print-js';
import Spinner from "../Spinner.js";

const Cuota = ({ cuota, getCreditos, setDatosPrint }) => {
    const {user} = useContext(UserContext);
    
    const [cuotaEstado, setCuotaEstado] = useState(cuota.cuota_estado);
    const [loadingPrint, setLoadingPrint] = useState(false);
    
    const getDataCredito = async () => {
        setLoadingPrint(true);

        // console.log('cuota:', cuota);

        const creditoData = await axios.get( getAPIBaseUrl()+`creditos/byid/${cuota.credito_id}` );
        const credito = creditoData.data.message[0];
        // console.log('credito:', credito);

        const clienteData = await axios.get( getAPIBaseUrl()+`clientes/byid/${credito.cliente_id}` );
        const cliente = clienteData.data.message[0];
        // console.log('cliente:', cliente);
    
        const comercioData = await axios.get( getAPIBaseUrl()+`comercios/byid/${credito.comercio_id}` );
        const comercio = comercioData.data.message[0];
        // console.log('comercio:', comercio);

        setDatosPrint({
            clienteNombre: cliente.cliente_nombre +' '+cliente.cliente_apellidos,
            clienteDocumento: cliente.cliente_documento,
            clienteDireccion: cliente.cliente_direccion,
            clienteCupo: convertirACOP(cliente.cliente_cupo_aprobado),
            comercioNombre: comercio.comercio_nombre,
            fechaPago: cuota.cuota_fecha_pagado.split(' ')[0],
            creditoMonto: convertirACOP(credito.credito_monto),
            productoServ: credito.credito_producto_servicio,
            frecPago: credito.credito_frecuencia,
            pagare: credito.credito_id +'-'+ cuota.cuota_id +'-'+ cuota.cuota_numero,
            fechaVencim: cuota.cuota_fecha_vencimiento,
            abonoCapital: convertirACOP(cuota.cuota_capital),
            interesCorr: convertirACOP(cuota.cuota_interes_corriente),
            aval: convertirACOP(cuota.cuota_aval),
            diasMora: cuota.cuota_dias_mora,
            interesMora: convertirACOP(cuota.cuota_interes_mora),
            totPagado: convertirACOP(cuota.cuota_total)
        });

        setTimeout(() => {
            printJS({
                printable: 'imprimir-comprobante', 
                type: 'html',
                documentTitle: 'Imprimir Comprobante',
                css: [
                    `${process.env.PUBLIC_URL + "/theme/src/styles/media.css"}`,
                    `${process.env.PUBLIC_URL + "/theme/src/styles/style.css"}`,
                    `${process.env.PUBLIC_URL + "/theme/src/styles/theme.css"}`,
                    `${process.env.PUBLIC_URL + "/theme/vendors/styles/core.min.css"}`,
                    `${process.env.PUBLIC_URL + "/theme/vendors/styles/icon-font.min.css"}`, 
                    `${process.env.PUBLIC_URL + "/theme/vendors/styles/style.min.css"}`
                ],
                scanStyles: false,
                maxWidth: 1000
            });

            setDatosPrint({});
            
            setLoadingPrint(false);
        }, 0);
    }

    const pagarCuota = async (creditoId, cuotaId) => {
        // console.log('creditoId:',creditoId);
        // console.log('cuotaId:', cuotaId);

        try {
            const responsePagarCuota = await axios.post( getAPIBaseUrl()+`cuotas/pagarCuota`, {
                creditoId: creditoId,
                cuotaId: cuotaId
            });

            // console.log('responsePagarCuota:', responsePagarCuota.data);

            if(!responsePagarCuota.data.success) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error al registrar el pago de la cuota',
                    text: responsePagarCuota.data.message,
                    // footer: '<a href="">Why do I have this issue?</a>'
                });
                return;
            } else {
                /**
                 * Cambiar estado de la cuota a pagado
                 */
                setCuotaEstado('pagado');

                Swal.fire({
                    icon: 'success',
                    title: 'Exito',
                    text: 'El pago de la cuota se ha registrado correctamente',
                    // footer: '<a href="">Why do I have this issue?</a>'
                });
            }

            if(getCreditos) { getCreditos(); }

        } catch (error) {
            // console.error( 'Error creando AprobarCupo', error );
            Swal.fire({
                icon: 'error',
                title: 'Error interno pagar cuota',
                text: error,
                // footer: '<a href="">Why do I have this issue?</a>'
            });
            return;
        }
    }

    const handlePagarCuota = (cuota_numero, creditoId, cuotaId) => () => {
        Swal.fire({
            title: 'Confirma el pago de la cuota #' + cuota_numero + ' ?',
            text: 'Pagar para Confirmar',
            icon: 'warning',
            confirmButtonText: 'Pagar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            // confirmButtonClass: 'btn btn-success',
            // cancelButtonClass: 'btn btn-danger'
        }).then((result) => {
            if(result.isConfirmed) {
                // console.log('Cambiar estado por pagada:', cuota_numero);
                pagarCuota(creditoId, cuotaId);
            }
        });
    }

    return (
        <tr>
            <td>{ cuota.cuota_numero }</td>
            <td className={"text-center"}>
                <div style={{'width': '120px'}}>
                    { cuota.cuota_fecha_vencimiento }
                    {/* <br /> (Estado: { cuotaEstado }) */}
                </div>

                {
                    user[0].usuario_rol === 'admin' && ( cuotaEstado === 'vigente' || cuotaEstado === 'retrasado' ) ?
                        <button
                            type="button"
                            className="btn btn-sm btn-block btn-success"
                            onClick={ handlePagarCuota(cuota.cuota_numero, cuota.credito_id, cuota.cuota_id) }
                        >
                            Pagar
                        </button>
                    :
                        <></>
                }

                {
                    cuotaEstado === 'pagado' ?
                        <>
                            <button
                                type="button"
                                className="btn btn-sm btn-block btn-info"
                                onClick={getDataCredito}
                            >
                                { loadingPrint ? <Spinner sm={true} /> : 'Comprobante' }
                            </button>
                        </>
                    :
                        <></>
                }

            </td>
            <td>{ convertirACOP(cuota.cuota_capital) }</td>
            <td>{ convertirACOP(cuota.cuota_interes_corriente) }</td>
            <td>{ convertirACOP(cuota.cuota_aval) }</td>
            <td>{ cuota.cuota_dias_mora }</td>
            <td>{ convertirACOP(cuota.cuota_interes_mora) }</td>
            <td>{ convertirACOP(cuota.cuota_total) }</td>
            <td>
                { cuotaEstado === 'pendiente' ? <span className="badge badge-pill badge-warning" data-toggle="tooltip" data-placement="top" title="Pendiente">Pendiente</span> : <></> }
                { cuotaEstado === 'vigente' ? <span className="badge badge-pill badge-info" data-toggle="tooltip" data-placement="top" title="Vigente">Vigente</span> : <></> }
                { cuotaEstado === 'pagado' ? <span className="badge badge-pill badge-success" data-toggle="tooltip" data-placement="top" title="Pagada">Pagada</span> : <></> }
                { cuotaEstado === 'retrasado' ? <span className="badge badge-pill badge-danger" data-toggle="tooltip" data-placement="top" title="Retrasada">Retrasada</span> : <></> }
                { cuotaEstado === 'rechazado' ? <span className="badge badge-pill badge-secondary" data-toggle="tooltip" data-placement="top" title="Retrasada">Rechazada</span> : <></> }
            </td>
        </tr>
    )
}

export default Cuota