// import axios from 'axios';
import Swal  from 'sweetalert2';

const EditarCliente = ({ datosCliente, clienteObj, cliente, setCliente, handleChange, handleChangeDatosCliente, baseUrl }) => {
    const handleSubmitEditarCliente = async (evt) => {
        evt.preventDefault();
        try {
            // const response = await axios.post( `${baseUrl}/editar`, cliente );
            // console.log( 'response:', response.data);

            Swal.fire({
                title: 'Cliente Editado!',
                text: 'Correctamente!',
                icon: 'success',
                confirmButtonText: 'Ok',
                // showCancelButton: true,
                confirmButtonClass: 'btn btn-success',
                // cancelButtonClass: 'btn btn-danger'
              }).then((result) => {
                console.log('result:', result);
                // if(result.isConfirmed) {
                //     // setCliente(clienteObj);
                // }
              });
        } catch (error) {
            console.error( 'Error creando cliente', error );
        }
    }

    return (
        <form onSubmit={ handleSubmitEditarCliente }>
            <div className="form-group">
                <label htmlFor="clienteNombreEdit">Nombre</label>
                <input
                    className="form-control"
                    id="clienteNombreEdit"
                    name="clienteNombreEdit"
                    type="text"
                    placeholder="Nombre"
                    defaultValue={ datosCliente.clienteNombre }
                    // value={ datosCliente.clienteNombre }
                    // onChange={ handleChangeDatosCliente }
                    disabled
                    required
                />
            </div>
    
            <div className="form-group"> 
                <label htmlFor="clienteApellidosEdit">Apellidos</label>
                <input
                    className="form-control"
                    id="clienteApellidosEdit"
                    name="clienteApellidosEdit"
                    type="text" 
                    placeholder="Apellidos"
                    defaultValue={ datosCliente.clienteApellidos }
                    // value={ datosCliente.clienteApellidos }
                    // onChange={ handleChangeDatosCliente }
                    disabled
                    required 
                />
            </div>

            <div className="row">
                <div className="col-md-5 col-sm-12">
                    <div className="form-group"> 
                        <label htmlFor="clienteTipoDocumentoEdit">Tipo Documento</label>
                        <input
                            className="form-control"
                            id="clienteTipoDocumentoEdit"
                            name="clienteTipoDocumentoEdit"
                            type="text" 
                            placeholder="Apellidos"
                            defaultValue={ datosCliente.clienteTipoDocumento }
                            // value={ datosCliente.clienteTipoDocumento }
                            // onChange={ handleChangeDatosCliente }
                            disabled
                            required 
                        />
                    </div>
                </div>
            
                <div className="col-md-7 col-sm-12">
                    <div className="form-group"> 
                        <label htmlFor="clienteNumeroDocumentoEdit">Número de documento</label>
                        <input
                            className="form-control" 
                            id="clienteNumeroDocumentoEdit"
                            name="clienteNumeroDocumentoEdit"
                            type="number"
                            placeholder="Número de documento"
                            defaultValue={ datosCliente.clienteDocumento }
                            // value={ datosCliente.clienteNumeroDocumento }
                            // onChange={ handleChangeDatosCliente }
                            disabled
                            required 
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 col-sm-12">
                    <div className="form-group"> 
                        <label htmlFor="clienteDireccionEdit">Dirección</label>
                        <input
                            className="form-control" 
                            id="clienteDireccionEdit"
                            name="clienteDireccionEdit"
                            type="text" 
                            placeholder="Dirección" 
                            defaultValue={ datosCliente.clienteDireccion }
                            // value={ datosCliente.clienteDireccionEdit }
                            // onChange={ handleChangeDatosCliente }
                            required
                        />
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 col-sm-12">
                    <button type="submit" className="btn btn-block btn-primary">
                        Editar
                    </button>
                </div>
            </div>
        </form>
    )
}

export default EditarCliente