import {useEffect} from "react";

const ClienteFoto = ({
    tituloLabel, idFileElem, idFileList, idImg, idFormGroup,
    credito, setCredito
}) => {
    const fileElemFotoDocPersona   = document.querySelector('#'+idFileElem),
          fileListFotoDocPersona   = document.querySelector('#'+idFileList);

    // const readFile = (evt) => {
    //     console.log('foto a guardar:',  evt.target.result);

    //     if(idFileElem === 'fileElemFotoDocPersona') {
    //         setCredito({
    //             ...credito,
    //             nuevoCreditoFotografiaCliente : evt.target.result,
    //             validarFotoCliente: false
    //         });
    //     }
    //     if(idFileElem === 'fileElemFotoDocFrente') {
    //         setCredito({
    //             ...credito,
    //             nuevoCreditoFotografiaFrenteDoc : evt.target.result,
    //             validarFotoFrenteDoc: false
    //         });
    //     }
    //     if(idFileElem === 'fileElemFotoDocReverso') {
    //         setCredito({
    //             ...credito,
    //             nuevoCreditoFotografiaRevDoc : evt.target.result,
    //             validarFotoRevDoc: false
    //         });
    //     }
    // }

    const changeFile = () => {
        // console.log('files.length:', fileElemFotoDocPersona.files.length);

        if (!fileElemFotoDocPersona.files.length) {
            fileListFotoDocPersona.innerHTML = "<p> No se selecciono ninguna foto! </p>";
        } else {
            var file = fileElemFotoDocPersona.files[0];

            // console.log('file:', file);
            // console.log('Blob1:', file instanceof Blob);

            if(file) {
                if(idFileElem === 'fileElemFotoDocPersona') {
                    setCredito({
                        ...credito,
                        nuevoCreditoFotografiaCliente : file,
                        validarFotoCliente: false
                    });
                }
                if(idFileElem === 'fileElemFotoDocFrente') {
                    setCredito({
                        ...credito,
                        nuevoCreditoFotografiaFrenteDoc : file,
                        validarFotoFrenteDoc: false
                    });
                }
                if(idFileElem === 'fileElemFotoDocReverso') {
                    setCredito({
                        ...credito,
                        nuevoCreditoFotografiaRevDoc : file,
                        validarFotoRevDoc: false
                    });
                }
            }

            // Vista previa
            fileListFotoDocPersona.innerHTML = "";
            const img  = document.createElement("img");
            img.setAttribute('id', idImg );
            img.src    = URL.createObjectURL(file);
            img.height = 60;
            img.style = 'border-radius: 13px';
            img.onload = () => {
                URL.revokeObjectURL(img.src);
            };
            fileListFotoDocPersona.appendChild(img);




            // var reader = new FileReader();
            // // reader.addEventListener('load', readFile);
            // // reader.readAsDataURL(file);
            // reader.onload = (evt) => {
            //     console.log('evt:', evt);
            //     console.log('reader.result:', reader.result);

            //     // const blob = new Blob([reader.result], {type: "image/png"});
            //     // const blob = fileElemFotoDocPersona;
            //     const blob = file;

            //     // const blob = file;
            //     console.log('blob:', blob);

            //     if(idFileElem === 'fileElemFotoDocPersona') {
            //         setCredito({
            //             ...credito,
            //             nuevoCreditoFotografiaCliente : blob,
            //             validarFotoCliente: false
            //         });
            //     }
            //     if(idFileElem === 'fileElemFotoDocFrente') {
            //         setCredito({
            //             ...credito,
            //             nuevoCreditoFotografiaFrenteDoc : blob,
            //             validarFotoFrenteDoc: false
            //         });
            //     }
            //     if(idFileElem === 'fileElemFotoDocReverso') {
            //         setCredito({
            //             ...credito,
            //             nuevoCreditoFotografiaRevDoc : blob,
            //             validarFotoRevDoc: false
            //         });
            //     }


            //     // const url = URL.createObjectURL(blob, {type: "image/png"});
            //     // console.log('url:', url);
            //     // const a = document.createElement("a");
            //     // a.href = url;
            //     // a.download = "image.png";
            //     // a.click();
            // }
            // reader.readAsArrayBuffer(file);




            // const info     = document.createElement("small");
            // info.setAttribute('id', idImg+'descrip');
            // info.className = 'form-text text-muted';
            // info.innerHTML = `${file.name}: ${file.size} bytes`;
            // fileListFotoDocPersona.appendChild(info);
        }
    }

    // const handleClickBtn = (evt) => {
    //     evt.preventDefault();
    //     console.log('evt:', evt);
    // }

    useEffect(() => {
        const img = document.getElementById(idImg);
        if(credito.limpiarFoto && img) {
            img.remove();

            // const descrip = document.getElementById(idImg+'descrip');
            // descrip.remove();
        }
    }, [credito.limpiarFoto, idImg, idFileElem]);

    return (
        <div className="row">
            <div className="col-md-6 col-sm-12">
                <div 
                    id={ idFormGroup }
                    className={ (credito.validarFotoCliente && (idFileElem === 'fileElemFotoDocPersona')) ||
                                (credito.validarFotoFrenteDoc && (idFileElem === 'fileElemFotoDocFrente')) || 
                                (credito.validarFotoRevDoc && (idFileElem === 'fileElemFotoDocReverso'))
                                ? "form-group has-danger" : "form-group" }
                >
                    <label htmlFor={ idFileElem }>
                        { tituloLabel }
                    </label>
                    <br />
                    <input
                        type="file"
                        id={ idFileElem }
                        name={ idFileElem }
                        accept="image/*"
                        capture="camera"
                        // capture="user"
                        style={{ 'display': 'none' }}
                        onChange={changeFile}
                    />
                    <button 
                        type="button"
                        className="btn btn-primary mb-10"
                        // onClick={ handleClickBtn }
                        onClick={() => { 
                            if(idFileElem) {
                                fileElemFotoDocPersona.click(); 
                            }
                        }}
                    >
                        Tomar Foto
                    </button>
                    
                    { credito.validarFotoCliente && (idFileElem === 'fileElemFotoDocPersona') ?
                      <div className="has-danger">Fotografía del cliente requerida.</div> : <></> }

                    { credito.validarFotoFrenteDoc && (idFileElem === 'fileElemFotoDocFrente') ?
                    <div className="has-danger">Fotografía del frente del documento requerida.</div> : <></> }

                    { credito.validarFotoRevDoc && (idFileElem === 'fileElemFotoDocReverso') ?
                    <div className="has-danger">Fotografía del reverso del documento requerida.</div> : <></> }
                </div>
            </div>

            <div className="col-md-6 col-sm-12">
                <div id={ idFileList }></div>
            </div>
        </div>
    )
}

export default ClienteFoto