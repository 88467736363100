/* eslint-disable no-unused-vars */
import axios from "axios";
import { getAPIBaseUrl } from "./components/utils";

try {
    // const getServerInfo = await axios.get( `${getAPIBaseUrl()}comercios/getserverinfo`);
    // if(getServerInfo.data.success){
    //     console.log('getServerInfo:', getServerInfo.data.message);
    // }

    /**
     * Calcular los intereses según los dias de retraso de todos los creditos vigentes y retrasados
     * al cargar la app
     */
    const responseCalcularIntereses = await axios.get( `${getAPIBaseUrl()}creditos/calcularDiasInteresesMoraCreditos`);
    // console.log('responseCalcularIntereses:', responseCalcularIntereses.data);

    // Verificar si no hay comercios creados
    const responseComercios = await axios.get( `${getAPIBaseUrl()}comercios`);
    // console.log('responseComercios:', responseComercios);
    // console.log('responseComercios.data:', responseComercios.data);
    // console.log('responseComercios.data.length:', responseComercios.data.length);

    if(responseComercios.data && responseComercios.data.length === 0) {
        // Datos del comercio 1
        const dataComercio1 = {
            tipo: 'inicial',
            com: 1
        }
        const responseComercioNuevo1 = await axios.post( getAPIBaseUrl()+`comercios/nuevo`, dataComercio1 );
        // console.log( 'responseComercioNuevo1:', responseComercioNuevo1.data);
        const lastInsertComercioId1 = responseComercioNuevo1.data.lastInsertComercioId;

        // Datos del comercio 2
        const dataComercio2 = {
            tipo: 'inicial',
            com: 2
        }
        const responseComercioNuevo2 = await axios.post( getAPIBaseUrl()+`comercios/nuevo`, dataComercio2 );
        // console.log( 'responseComercioNuevo2:', responseComercioNuevo2.data);
        const lastInsertComercioId2 = responseComercioNuevo2.data.lastInsertComercioId;

        //----------- Usuarios -------------

        // Verificar si no hay usuarios creados
        const responseUsuarios = await axios.get( getAPIBaseUrl()+`usuarios`);
        // console.log('responseUsuarios:', responseUsuarios.data.length);

        if(responseUsuarios.data.length === 0 && lastInsertComercioId1 && lastInsertComercioId2) {
            // Datos del usuario 1
            const dataUsuario1 = {
                tipo: 'inicial',
                usu: 1,
                comercioId: lastInsertComercioId1
            }
            const responseUsuarioNuevo1 = await axios.post( getAPIBaseUrl()+`usuarios/nuevo`, dataUsuario1);
            // console.log( 'responseUsuarioNuevo1:', responseUsuarioNuevo1.data);

            // Datos del usuario 2
            const dataUsuario2 = {
                tipo: 'inicial',
                usu: 2,
                comercioId: lastInsertComercioId1
            }
            const responseUsuarioNuevo2 = await axios.post( getAPIBaseUrl()+`usuarios/nuevo`, dataUsuario2);
            // console.log( 'responseUsuarioNuevo2:', responseUsuarioNuevo2.data);

            // Datos del usuario 3
            const dataUsuario3 = {
                tipo: 'inicial',
                usu: 3,
                comercioId: lastInsertComercioId2
            }
            const responseUsuarioNuevo3 = await axios.post( getAPIBaseUrl()+`usuarios/nuevo`, dataUsuario3);
            // console.log( 'responseUsuarioNuevo3:', responseUsuarioNuevo3.data);
        }
    }
    
} catch (error) {
    console.error('Error critico fixtures:', error);

    // if(error.message === 'Network Error') {
    //     console.error( 'Sin conexión' );
    // } else {
    //     console.error('Error Fixtures', error.response.data);
    // }
}