import React, { useContext, useState } from 'react';
import DatosCliente from './DatosCliente.js';
import DatosCredito from '../Credito/DatosCredito.js';
import { UserContext } from '../../context/UserContext.js';
import { getAPIBaseUrl } from '../utils.js';

const Cliente = () => {
    const {user} = useContext(UserContext);
    const [loading, setLoading] = useState(false);

    let clienteObj = {
        // Form Buscar Cliente
        clienteDocumentoBuscar    : '',
        clienteId                 : '',
        clienteCreditosPendientes : [],
        clienteCreditosVigentes   : [],
        clienteCreditosRetrasados : [],
        clienteCreditosHistoricos : [],
    
        // Form Crear Cliente
        usuarioId                : user[0].usuario_id,
        comercioId               : user[0].comercio_id,
        clienteNombre            : '',
        clienteApellidos         : '',
        clienteTipoDocumento     : '',
        clienteNumeroDocumento   : '',
        clienteCupoAprobado      : 0,
        clienteDireccion         : '',
        clienteCorreoElectronico : '',
        clienteCelular           : '',
    
        // Form Editar Cliene
        clienteFoto                : '',
        clienteNombreEdit          : '',
        clienteApellidosEdit       : '',
        clienteTipoDocumentoEdit   : '',
        clienteNumeroDocumentoEdit : '',
        clienteDireccionEdit       : '',

        // referencia personal
        refPersNombre              : '',
        refPersCelular             : '',

        // referencia laboral
        refLabNombre               : '',
        refLabCelular              : ''
    };

    const [cliente, setCliente] = useState(clienteObj);

    const handleChange = (evt) => {
        /*
            evt.target es el elemento que ejecuto el evento
            name identifica el input y value describe el valor actual
        */
        const { target }      = evt;
        const { name, value } = target;

        /*
            Este snippet:
            1. Clona el estado actual
            2. Reemplaza solo el valor del
            input que ejecutó el evento
        */
        const newCliente = {
            ...cliente,
            [name]: value,
        };

        // console.log('newCliente:', newCliente);

        // Sincroniza el estado de nuevo
        setCliente(newCliente);
    }

    return (
        <>
            <div className="row clearfix">
                {/* Inicio sección datos cliente */}
                <div className="col-md-4">
                    {/* <div className="pd-20 card-box mb-30"> */}
                    <DatosCliente
                        clienteObj={clienteObj}
                        // clienteObj={cliente}
                        cliente={cliente}
                        setCliente={setCliente}
                        handleChange={handleChange}
                        baseUrl={getAPIBaseUrl()}
                        loading={loading}
                        setLoading={setLoading}
                    />
                    {/* </div> */}
                </div>
                {/* Fin sección datos cliente */}


                {/* Inicio sección datos credito */}
                <div id="datosCredito" className="col-md-8">
                    <div className="pd-20 card-box mb-30">
                        <DatosCredito
                            cliente={cliente}
                            loading={loading}
                        />
                    </div>
                </div>
                {/* Fin sección datos credito */}
            </div>
        </>
    )
}

export default Cliente