const Spinner = ({ sm }) => {
    return (
        <>
            <span
                // className={ sm ? "spinner-grow spinner-grow-sm" : "spinner-grow" }
                className={ sm ? "spinner-border spinner-border-sm" : "spinner-border" }
                role="status"
                aria-hidden="true"
            ></span>
            <span className="sr-only">Cargando...</span>

            { sm ? <>&nbsp;&nbsp;</>: '' }
        </>
    )
}

export default Spinner