const ComprobantePago = ({datosPrint}) => {
    return (
        <div className="invoice-wrap text-left" id="imprimir-comprobante">
            <div className="invoice-box">
                <div className="invoice-header">
                    <div className="logo text-center">
                        <img 
                            src={`${process.env.PUBLIC_URL + "/logo-creditxtatts.png"}`} 
                            alt=""
                            style={{'height': '60px', 'borderRadius': '13px'}}
                        />
                    </div>
                </div>
                
                <div className="row">
                    <div className="col">
                        <h6 className="mb-10">{ datosPrint.clienteNombre }</h6>
                        <p className="font-14 mb-5">
                            Documento: <strong className="weight-600">{ datosPrint.clienteDocumento }</strong>
                        </p>
                        <p className="font-14 mb-5">
                            Dirección: <strong className="weight-600">{ datosPrint.clienteDireccion }</strong>
                        </p>
                        <p className="font-14 mb-5">
                            Cupo: <strong className="weight-600">{ datosPrint.clienteCupo }</strong>
                        </p>
                        <p className="font-14 mb-5">
                            Fecha Pago: <strong className="weight-600">{ datosPrint.fechaPago }</strong>
                        </p>
                        <p className="font-14 mb-5">
                            Comercio: <strong className="weight-600">{ datosPrint.comercioNombre }</strong>
                        </p>
                    </div>

                    {/* <div className="col-md-4"> */}
                    <div className="col">
                        <p className="font-14 mb-5">
                            Monto del credito: <strong className="weight-600">{ datosPrint.creditoMonto }</strong>
                        </p>
                        <p className="font-14 mb-5">
                            Producto o Servicio: <strong className="weight-600">{ datosPrint.productoServ }</strong>
                        </p>
                        <p className="font-14 mb-5">
                            Frecuencia de Pago: <strong className="weight-600">{ datosPrint.frecPago }</strong>
                        </p>
                        <p className="font-14 mb-5">
                            Pagaré: <strong className="weight-600">{ datosPrint.pagare }</strong>
                        </p>
                        <p className="font-14 mb-5">
                            Fecha Vencimiento: <strong className="weight-600">{ datosPrint.fechaVencim }</strong>
                        </p>
                    </div>

                    <div className="col">
                        <p className="font-14 mb-5">
                            Abono a capital: <strong className="weight-600">{ datosPrint.abonoCapital }</strong>
                        </p>
                        <p className="font-14 mb-5">
                            Interés corriente: <strong className="weight-600">{ datosPrint.interesCorr }</strong>
                        </p>
                        <p className="font-14 mb-5">
                            Aval: <strong className="weight-600">{ datosPrint.aval }</strong>
                        </p>
                        <p className="font-14 mb-5">
                            Días de mora: <strong className="weight-600">{ datosPrint.diasMora }</strong>
                        </p>
                        <p className="font-14 mb-5">
                            Interés de mora: <strong className="weight-600">{ datosPrint.interesMora }</strong>
                        </p>
                        <p className="font-14 mb-5">
                            Total pagado: <strong className="weight-600">{ datosPrint.totPagado }</strong>
                        </p>
                    </div>
                </div>

                <hr />

                <small>
                    En caso de mora de cualquiera de las cuotas acepto pagar los gastos de cobranza y honorarios, además se vencerá automáticamente el plazo de las restantes y pagaré intereses a la máxima tasa legal vigente. 
                    Autorizo a Creditxtatts para consultar las bases de datos y reportar el incumplimiento en el pago de este título a las centrales de información de riesgos y como referencia comercial. 
                    También para actualizar periodicamente mi información personal, laboral, comercial y financiera. 
                    Reconozco y acepto expresamente los valores cobrados por concepto de capital, interés remuneratorio y aval e iva del aval.
                </small>
            </div>
        </div>
    )
};

export default ComprobantePago;