import axios from 'axios';
import Swal  from 'sweetalert2';
import {
    // convertirACOP,
    getAPIBaseUrl } from '../utils';
import BootstrapSelectPicker from '../SelectPicker';
import {useState, useEffect} from 'react';
import Spinner from '../Spinner';

const ModalEditarCliente = ({ clienteId, refresh='' }) => {
    const [loading, setLoading] = useState(false);
    const [loadingCupoAprobado, setLoadingCupoAprobado] = useState(false);
    const [cliente, setCliente] = useState({
        clienteEditId: '',
        clienteEditNombre: '',
        clienteEditApellidos: '',
        clienteEditTipoDocumento: '',
        clienteEditNumeroDocumento: '',
        clienteEditCupoAprobado: '',
        clienteEditDireccion: '',
        clienteEditEmail: '',
        clienteEditCelular: '',
        clienteEditRefPersNombre: '',
        clienteEditRefPersCelular: '',
        clienteEditRefLabNombre: '',
        clienteEditRefLabCelular: '',
        // clienteEditFoto: '',
        // clienteEditFotoFrenteDoc: '',
        // clienteEditFotoRevDoc: ''
    });

    useEffect(() => {
        const getClienteData = async () => {
            setLoading(true);
            if(!clienteId) { return; }
            try {
                const response = await axios.get( getAPIBaseUrl()+`clientes/byid/${clienteId}`);
                const clienteData = response.data.message[0];
                // console.log('cliente data:', clienteData);
                
                if(clienteData.cliente_id !== '') {
                    // const getfotoultimocredito = await axios.get( `${getAPIBaseUrl()}creditos/getfotoultimocredito/byidcliente/${clienteData.cliente_id}` );                
                    // console.log('credito_fotografia_cliente:', getfotoultimocredito.data);

                    setCliente({
                        clienteEditId: clienteData.cliente_id,
                        clienteEditNombre: clienteData.cliente_nombre,
                        clienteEditApellidos: clienteData.cliente_apellidos,
                        clienteEditTipoDocumento: clienteData.cliente_tipo_documento,
                        clienteEditNumeroDocumento: clienteData.cliente_documento,
                        clienteEditCupoAprobado: clienteData.cliente_cupo_aprobado,
                        clienteEditDireccion: clienteData.cliente_direccion,
                        clienteEditEmail: clienteData.cliente_correo_electronico,
                        clienteEditCelular: clienteData.cliente_celular,
                        clienteEditRefPersNombre: clienteData.cliente_referencia_personal_nombre,
                        clienteEditRefPersCelular: clienteData.cliente_referencia_personal_celular,
                        clienteEditRefLabNombre: clienteData.cliente_referencia_laboral_nombre,
                        clienteEditRefLabCelular: clienteData.cliente_referencia_laboral_celular,
                        // clienteEditFoto: getfotoultimocredito.data.credito_fotografia_cliente,
                        // clienteEditFotoFrenteDoc: getfotoultimocredito.data.credito_fotografia_frente_documento,
                        // clienteEditFotoRevDoc: getfotoultimocredito.data.credito_fotografia_reverso_documento,
                    });

                    // setCupo(clienteData.cliente_cupo_aprobado);
                    setLoading(false);
                }
            } catch (error) {
                if(error.code !== 'ECONNABORTED') {
                    console.error( 'Error al traer los datos de los clientes', error );
                }
            }
        }

        getClienteData();
    }, [clienteId]);

    const handleChange = (e) => {
        setCliente({
            ...cliente,
            [e.target.name]: e.target.value
        });
    }

    const getCupoDatacredito = async (e) => {
        const documento = e.target.value;
        // console.log('documento:', documento);

        setLoadingCupoAprobado(true);

        const respDatacredito =  await axios.get( getAPIBaseUrl()+`clientes/getcupodatacredito/${documento}`);
        console.log('respDatacredito:', respDatacredito);

        if(respDatacredito.data.success) {
            const cupoDatacredito = JSON.parse(respDatacredito.data.message).data.availableCreditLimit;
            // console.log('cupoDatacredito:', convertirACOP(cupoDatacredito));

            setCliente({
                ...cliente,
                clienteEditCupoAprobado: cupoDatacredito
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error obteniendo el cupo del cliente',
                text: respDatacredito.data.message,
                // footer: '<a href="">Why do I have this issue?</a>'
            });
        }

        setLoadingCupoAprobado(false);
    }

    const handleSubmitEditarCliente = async (evt) => {
        evt.preventDefault();

        // console.log('Submit form ...', cliente);

        setLoading(true);

        try {
            const response = await axios.post( getAPIBaseUrl()+`clientes/modificar`, cliente );
            // console.log( 'response:', response.data);

            if(!response.data.success) {
                setLoading(false);

                Swal.fire({
                    icon: 'error',
                    title: 'Error cliente',
                    text: response.data.message,
                    // footer: '<a href="">Why do I have this issue?</a>'
                });
                return;
            } else {
                setLoading(false);

                Swal.fire({
                    icon: 'success',
                    title: 'Cliente Editado!',
                    text: 'Cliente editado correctamente',
                    // footer: '<a href="">Why do I have this issue?</a>'
                });

                refresh();
            }
        } catch (error) {
            setLoading(false);

            Swal.fire({
                icon: 'error',
                title: 'Error interno cliente',
                text: error,
                // footer: '<a href="">Why do I have this issue?</a>'
            });
        }
    }

    return (
        <form onSubmit={ handleSubmitEditarCliente }>
            <div
                className="modal fade bs-example-modal-lg"
                id="modalEditarCliente"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-scrollable modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="myLargeModalLabel">
                                Editar Cliente
                            </h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-hidden="true"
                            >
                                ×
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="clienteEditNombre">Nombre</label>
                                        <input
                                            className="form-control"
                                            id="clienteEditNombre"
                                            name="clienteEditNombre"
                                            type="text"
                                            placeholder="Nombre"
                                            value={ cliente.clienteEditNombre }
                                            onChange={ handleChange }
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="clienteEditApellidos">Apellidos</label>
                                        <input
                                            className="form-control"
                                            id="clienteEditApellidos"
                                            name="clienteEditApellidos"
                                            type="text" 
                                            placeholder="Apellidos"
                                            value={ cliente.clienteEditApellidos }
                                            onChange={ handleChange }
                                            required 
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="clienteEditTipoDocumento">Tipo Documento</label>
                                        <BootstrapSelectPicker
                                            idNameSelect={'clienteEditTipoDocumento'}
                                            selectValue={cliente.clienteEditTipoDocumento}
                                            handleChange={handleChange}
                                            options={[
                                                { value: 'CC', name: 'Cédula de ciudadanía' },
                                                { value: 'CE', name: 'Cédula de extranjería' },
                                                { value: 'DIE', name: 'Documento de identificación extranjero' },
                                                { value: 'NIT', name: '(Nit) Número de Identificación Tributaria (Empresas)' },
                                                { value: 'P', name: 'Pasaporte' },
                                                { value: 'RCN', name: 'Registro civil de nacimiento' },
                                                { value: 'TI', name: 'Tarjeta de identidad' },
                                            ]}
                                            required={true}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="clienteEditNumeroDocumento">Número de documento</label>
                                        <input
                                            className="form-control" 
                                            id="clienteEditNumeroDocumento"
                                            name="clienteEditNumeroDocumento"
                                            type="number"
                                            placeholder="Número de documento"
                                            value={ cliente.clienteEditNumeroDocumento }
                                            onChange={ handleChange }
                                            onBlur={ getCupoDatacredito }
                                            required 
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <div className="form-group"> 
                                            <label htmlFor="clienteEditCupoAprobado">Cupo aprobado</label>
                                            <br />
                                            {/* { loadingCupoAprobado ? 
                                                <Spinner sm={true}/> 
                                              :
                                                <>
                                                    { convertirACOP(cliente.clienteEditCupoAprobado) }
                                                </>
                                            } */}

                                            
                                                <div className="input-group mb-3">
                                                    { loadingCupoAprobado ? 
                                                        <Spinner sm={true}/> 
                                                    :
                                                        
                                                        <>
                                                            {/* { convertirACOP(cliente.clienteEditCupoAprobado) } */}

                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">$</span>
                                                            </div>
                                                            <input
                                                                className="form-control" 
                                                                id="clienteEditCupoAprobado"
                                                                name="clienteEditCupoAprobado"
                                                                type="number"
                                                                placeholder="Cupo aprobado"
                                                                value={ cliente.clienteEditCupoAprobado }
                                                                onChange={ handleChange }
                                                                // readOnly
                                                                required
                                                            />
                                                            {/* <small className="has-danger">
                                                                Para solicitar el cupo debe comunicarse con un asesor.
                                                            </small> */}
                                                        </>
                                                    }
                                                </div>
                                           
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="clienteEditDireccion">Dirección</label>
                                        <input
                                            className="form-control" 
                                            id="clienteEditDireccion"
                                            name="clienteEditDireccion"
                                            type="text" 
                                            placeholder="Dirección" 
                                            value={ cliente.clienteEditDireccion }
                                            onChange={ handleChange }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="clienteEditEmail">Correo electrónico</label>
                                        <input
                                            className="form-control" 
                                            id="clienteEditEmail"
                                            name="clienteEditEmail"
                                            type="email"
                                            placeholder="Correo electrónico"
                                            value={ cliente.clienteEditEmail }
                                            onChange={ handleChange }
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="clienteEditCelular">Celular</label>
                                        <input
                                            className="form-control" 
                                            id="clienteEditCelular"
                                            name="clienteEditCelular"
                                            type="tel" 
                                            placeholder="Celular"
                                            value={ cliente.clienteEditCelular }
                                            onChange={ handleChange }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <hr />

                            {/* referencia personal */}

                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                <h4 className="modal-title" id="myLargeModalLabelComercio">
                                    Referencia Personal
                                </h4>
                                </div>
                            </div>

                            <hr />

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="clienteEditRefPersNombre">Nombre</label>
                                        <input
                                            className="form-control"
                                            id="clienteEditRefPersNombre"
                                            name="clienteEditRefPersNombre"
                                            type="text"
                                            placeholder="Nombre"
                                            value={ cliente.clienteEditRefPersNombre }
                                            onChange={ handleChange }
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="clienteEditRefPersCelular">Celular</label>
                                        <input
                                            className="form-control" 
                                            id="clienteEditRefPersCelular"
                                            name="clienteEditRefPersCelular"
                                            type="number"
                                            placeholder="Celular"
                                            value={ cliente.clienteEditRefPersCelular }
                                            onChange={ handleChange }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <hr />

                            {/* referencia laboral */}

                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                <h4 className="modal-title" id="myLargeModalLabelComercio">
                                    Referencia Laboral
                                </h4>
                                </div>
                            </div>

                            <hr />

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="clienteEditRefLabNombre">Nombre</label>
                                        <input
                                            className="form-control"
                                            id="clienteEditRefLabNombre"
                                            name="clienteEditRefLabNombre"
                                            type="text"
                                            placeholder="Nombre"
                                            value={ cliente.clienteEditRefLabNombre }
                                            onChange={ handleChange }
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group"> 
                                        <label htmlFor="clienteEditRefLabCelular">Celular</label>
                                        <input
                                            className="form-control" 
                                            id="clienteEditRefLabCelular"
                                            name="clienteEditRefLabCelular"
                                            type="number"
                                            placeholder="Celular"
                                            value={ cliente.clienteEditRefLabCelular }
                                            onChange={ handleChange }
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Cancelar
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={ loading }
                            >
                                { loading ? <Spinner sm={true}/> : <></> }
                                Guardar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default ModalEditarCliente