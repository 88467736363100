import { useEffect, useState } from "react";
import axios from "axios";
import { convertirACOP, getAPIBaseUrl } from "../utils";
import ModalAprobarCupo from "./ModalAprobarCupo";

import $ from 'jquery';
import "gridjs/dist/theme/mermaid.min.css";
import { Grid } from 'gridjs-react';
import { esES } from "gridjs/l10n";
import { _ } from "gridjs-react";
import Swal from "sweetalert2";
import ModalEditarCliente from "./ModalEditarCliente";

const ClientesCupo = () => {
    const [clientes, setClientes] = useState([]);
    const [clienteId, setClienteId] = useState('');
    const [loading, setLoading] = useState(true);

    const getClientes = async () => {
        try {
            const clientesResponse = await axios.get( getAPIBaseUrl()+`clientes`);
            // console.log('clientesResponse:', clientesResponse.data);
            setClientes(clientesResponse.data);

        } catch (error) {
            console.error( 'Error al traer los datos de los clientes', error );
        }
    }

    useEffect(() => {
        getClientes();

        $(function () {
            setTimeout(() => {
                // console.log('Init tooltip ...');
                $('[data-toggle="tooltip"]').tooltip();
                setLoading(false);
            }, 3000);
        });
    }, []);

    const handleEliminarCliente = (idCliente, documentoCliente) => (e) => {
        Swal.fire({
            title: `Esta seguro de eliminar el cliente con documento ${documentoCliente}?`,
            text: 'Eliminar para Confirmar',
            icon: 'error',
            confirmButtonText: 'Eliminar',
            cancelButtonText: 'Cancelar',
            showCancelButton: true,
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            reverseButtons: true

        }).then((result) => {
            if(result.isConfirmed) {
                // console.log('Eliminar Cliente:', idCliente);
                eliminarCliente(idCliente);
            }
        });
    }

    const eliminarCliente = async (idCliente) => {
        // console.log('id cliente eliminar:', idCliente);

        // Validar si el cliente tiene creditos relacionados
        const respCreditosCliente = await axios.get( getAPIBaseUrl()+`creditos/count/byclienteid/${idCliente}`);
        const nroCreditosCliente = respCreditosCliente.data[0].count;
        
        // console.log('nroCreditosCliente:', nroCreditosCliente);

        if(nroCreditosCliente > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error al eliminar cliente',
                text: 'El cliente no se puede eliminar por que tiene creditos relacionados.',
                // footer: '<a href="">Why do I have this issue?</a>'
            });
            return;
        } else {
            try {
                const responseElimCliente = await axios.post( getAPIBaseUrl()+`clientes/eliminar`, {
                    clienteId: idCliente
                });
    
                if(!responseElimCliente.data.success) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error eliminar cliente',
                        text: responseElimCliente.data.message,
                        // footer: '<a href="">Why do I have this issue?</a>'
                    });
                    return;

                } else {
                    Swal.fire({
                        icon: 'success',
                        title: 'Exito',
                        text: 'Cliente eliminado correctamente',
                        // footer: '<a href="">Why do I have this issue?</a>'
                    });
                    getClientes();
                }
    
            } catch (error) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error interno Eliminar cliente',
                    text: idCliente === '' ? 'Intente nuevamente' : error,
                    // footer: '<a href="">Why do I have this issue?</a>'
                });
                return;
            }
        }
    }

    return (
        <>
            <div className="pd-20 card-box mb-30">
                <div className="row mb-10">
                    <div className="col-md-8">
                        <div className="clearfix">
                            <div className="pull-left">
                                <h4 className="text-blue h4">Clientes</h4>
                                <p className="mb-5">Aprobar cupo a clientes</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4"></div>
                </div>

                <div className="table-responsive">
                    <Grid
                      columns={[
                        'Documento',
                        'Nombre',
                        'Ceular',
                        'Email',
                        'Cupo',
                        'Acciones'
                      ]}
                      data={
                            clientes.map(cliente => [
                                cliente.cliente_documento,
                                cliente.cliente_nombre + ' ' + cliente.cliente_apellidos,
                                cliente.cliente_celular,
                                cliente.cliente_correo_electronico,
                                convertirACOP(cliente.cliente_cupo_aprobado),
                                _(
                                    loading ? 
                                        <> Cargando ... </>
                                    :
                                        <>
                                            <span 
                                                data-toggle="tooltip"
                                                title="Aprobar Cupo"
                                            >
                                                <button 
                                                    type="button" 
                                                    className="btn btn-sm" 
                                                    data-bgcolor="#3d464d" 
                                                    data-color="#ffffff" 
                                                    style={{ 'color': 'rgb(255, 255, 255)', 'backgroundColor': 'rgb(61, 70, 77)' }}
                                                    id={cliente.cliente_id+"-aprobar-cupo"}
                                                    data-backdrop="static"
                                                    data-toggle="modal"
                                                    data-target="#modalAprobarCupo"
                                                    onClick={ () => { setClienteId(cliente.cliente_id) } }
                                                >
                                                    <i className="icon-copy ion-social-usd"></i>
                                                </button>
                                            </span>
                                            &nbsp;
                                            <span 
                                                data-toggle="tooltip"
                                                title="Editar"
                                            >
                                                <button 
                                                    type="button" 
                                                    className="btn btn-sm" 
                                                    data-bgcolor="#00b489" 
                                                    data-color="#ffffff" 
                                                    style={{ 'color': 'rgb(255, 255, 255)', 'backgroundColor': 'rgb(0, 180, 137)' }}
                                                    id={cliente.cliente_id+"-editar-cliente"}
                                                    data-backdrop="static"
                                                    data-toggle="modal"
                                                    data-target="#modalEditarCliente"
                                                    onClick={ () => { setClienteId(cliente.cliente_id) } }
                                                >
                                                    <i className="icon-copy ion-android-create"></i>
                                                </button>
                                            </span>
                                            &nbsp;
                                            <span 
                                                data-toggle="tooltip"
                                                title="Eliminar"
                                            >
                                                <button
                                                    type="button"
                                                    className="btn btn-sm"
                                                    data-bgcolor="#bd081c"
                                                    data-color="#ffffff"
                                                    style={{ 'color': 'rgb(255, 255, 255)', 'backgroundColor': 'rgb(189, 8, 28)' }}
                                                    id={cliente.cliente_id+"-eliminar-cliente"}
                                                    onClick={ handleEliminarCliente(cliente.cliente_id, cliente.cliente_documento) }
                                                >
                                                    <i className="icon-copy ion-android-cancel"></i>
                                                </button>
                                            </span>
                                        </>
                                )
                            ])
                        }
                      sort={ true }
                      search={ true }
                      pagination={{ limit: 8 }}
                      language={ esES }
                    />
                </div>
            </div>

            {/************* Modales ***************/}
            
            <ModalAprobarCupo
                clienteId={clienteId}
                refresh={getClientes}
            />

            <ModalEditarCliente
                clienteId={clienteId}
                refresh={getClientes}
            />
        </>
    );
}

export default ClientesCupo