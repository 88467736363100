import { useContext } from "react";
import Cuotas from "../Cuota/Cuotas";
import { convertirACOP, getAPIBaseUrl } from "../utils";
import axios from "axios";
import Swal from "sweetalert2";
import { UserContext } from "../../context/UserContext";

const Credito = ({
  credito,
  creditoTipo,
  dataParentElem,
  getCreditos = false,
  setClienteId = false,
}) => {
  const { user } = useContext(UserContext);

  const aprobarCredito = async (creditoId) => {
    try {
      const responseCredito = await axios.post(
        getAPIBaseUrl() + `creditos/cambiarEstado`,
        {
          creditoId: creditoId,
          estado: 'vigente',
        }
      );

      // console.log('responseCredito:', responseCredito.data);

      const responseCuota = await axios.post(
        getAPIBaseUrl() + `cuotas/cambiarEstadoMany`,
        {
          creditoId: creditoId,
          estado: "vigente",
        }
      );

      // console.log('responseCuota:', responseCuota.data);

      if (!responseCredito.data.success || !responseCuota.data.success) {
        Swal.fire({
          icon: "error",
          title: "Error al aprobar el credito, intente nuevamente",
          text: responseCredito.data.message,
          // footer: '<a href="">Why do I have this issue?</a>'
        });
        return;
      } else {
        Swal.fire({
          icon: "success",
          title: "Exito",
          text: "Credito aprobado correctamente",
          // footer: '<a href="">Why do I have this issue?</a>'
        });
      }

      if (getCreditos) {
        getCreditos();
      }
    } catch (error) {
      // console.error( 'Error creando AprobarCupo', error );
      Swal.fire({
        icon: "error",
        title: "Error interno Aprobar Credito",
        text: error,
        // footer: '<a href="">Why do I have this issue?</a>'
      });
      return;
    }
  };

  const handleAprobarCredito = (credito_id) => async (e) => {
    Swal.fire({
      title: "Desea Aprobar el Credito?",
      text: "Aprobar para Confirmar",
      icon: "success",
      confirmButtonText: "Aprobar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log('Cambiar estado por vigente');
        aprobarCredito(credito_id);
      }
    });
  };

  const rechazarCredito = async (creditoId) => {
    try {
      const response = await axios.post(
        getAPIBaseUrl() + `creditos/cambiarEstado`,
        {
          creditoId: creditoId,
          estado: "rechazado",
        }
      );

      const responseCuota = await axios.post(
        getAPIBaseUrl() + `cuotas/cambiarEstadoMany`,
        {
          creditoId: creditoId,
          estado: "rechazado",
        }
      );

      if (!response.data.success || !responseCuota.data.success) {
        Swal.fire({
          icon: "error",
          title: "Error rechazar credito",
          text: response.data.message,
          // footer: '<a href="">Why do I have this issue?</a>'
        });
        return;
      } else {
        Swal.fire({
          icon: "success",
          title: "Exito",
          text: "Credito rechazado correctamente",
          // footer: '<a href="">Why do I have this issue?</a>'
        });
      }

      if (getCreditos) {
        getCreditos();
      }
    } catch (error) {
      // console.error( 'Error creando AprobarCupo', error );
      Swal.fire({
        icon: "error",
        title: "Error interno Rechazar Credito",
        text: error,
        // footer: '<a href="">Why do I have this issue?</a>'
      });
      return;
    }
  };

  const handleRechazarCredito = (credito_id) => (e) => {
    Swal.fire({
      title: "Desea Rechazar el Credito?",
      text: "Rechazar para Confirmar",
      icon: "warning",
      confirmButtonText: "Rechazar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log('Cambiar estado por rechazado');
        rechazarCredito(credito_id);
      }
    });
  };

  const handleEliminarCredito = (credito_id) => (e) => {
    Swal.fire({
      title: "Desea Eliminar el Credito?",
      text: "Eliminar para Confirmar",
      icon: "error",
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      confirmButtonClass: "btn btn-success",
      cancelButtonClass: "btn btn-danger",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log('Cambiar estado por rechazado');
        eliminarCredito(credito_id);
      }
    });
  };

  const eliminarCredito = async (creditoId) => {
    try {
      const responseElCuotas = await axios.post(
        getAPIBaseUrl() + `cuotas/eliminar`,
        {
          creditoId: creditoId,
        }
      );

      if (responseElCuotas.data.success) {
        const response = await axios.post(
          getAPIBaseUrl() + `creditos/eliminar`,
          {
            creditoId: creditoId,
          }
        );

        if (!response.data.success) {
          Swal.fire({
            icon: "error",
            title: "Error eliminar credito",
            text: response.data.message,
            // footer: '<a href="">Why do I have this issue?</a>'
          });
          return;
        } else {
          Swal.fire({
            icon: "success",
            title: "Exito",
            text: "Credito eliminado correctamente",
            // footer: '<a href="">Why do I have this issue?</a>'
          });
        }

        if (getCreditos) {
          getCreditos();
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Error eliminar credito intente mas tarde",
          text: responseElCuotas.data.message,
        });
        return;
      }
    } catch (error) {
      // console.error( 'Error creando AprobarCupo', error );
      Swal.fire({
        icon: "error",
        title: "Error interno Eliminar Credito",
        text: error,
        // footer: '<a href="">Why do I have this issue?</a>'
      });
      return;
    }
  };

  const showClienteId = (e) => {
    setClienteId(e.target.id.split("-")[0]);
  };

  return (
    <div className="card">
      <div className="card-header" id={"heading-" + credito.credito_id}>
        <button
          className="btn btn-block collapsed"
          data-toggle="collapse"
          data-target={"#cred" + credito.credito_id}
          aria-controls={"cred" + credito.credito_id}
        >
          {credito.credito_producto_servicio} por {" "} {convertirACOP(credito.credito_monto)} <br />
          Compañia: {credito.comercio_nombre} <br />
          Cliente: {credito.cliente_documento} - {credito.cliente_nombre} {credito.cliente_apellidos} <br />
          Fecha creado: {credito.credito_fecha_creado}
        </button>
      </div>

      <div
        id={"cred" + credito.credito_id}
        className="collapse"
        aria-labelledby={"heading-" + credito.credito_id}
        data-parent={dataParentElem}
      >
        <div className="card-body">
          {
            <>
              {(user && user[0].usuario_rol) === "admin" ? (
                <div className="row mb-30">
                  <div className="col-md-12">
                    <button
                      type="button"
                      className="btn btn-link"
                      id={`${credito.cliente_id}-show-cliente-data`}
                      data-backdrop="static"
                      data-toggle="modal"
                      data-target={"#modalAprobarCupo"}
                      onClick={showClienteId}
                    >
                      Información detallada del cliente
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )}

              {(creditoTipo === "pendiente" || creditoTipo === "rechazado") && user && user[0].usuario_rol === "admin" ?
              (
                <div className="row mb-30">
                  {creditoTipo === "pendiente" &&
                  (user && user[0].usuario_rol) === "admin" ? (
                    <div className="col-md-4 mb-10">
                      <button
                        type="button"
                        className="btn btn-sm btn-block btn-success"
                        id={credito.credito_id + "-aprobar-credito"}
                        onClick={handleAprobarCredito(credito.credito_id)}
                      >
                        Aprobar
                      </button>
                    </div>
                  ) : (
                    <div className="col-md-4"></div>
                  )}

                  {creditoTipo === "pendiente" && (user && user[0].usuario_rol) === "admin" ?
                  (
                    <div className="col-md-4 mb-10">
                      <button
                        type="button"
                        className="btn btn-sm btn-block btn-warning"
                        id={credito.credito_id + "-rechazar-credito"}
                        onClick={handleRechazarCredito(credito.credito_id)}
                      >
                        Rechazar
                      </button>
                    </div>
                  ) : (
                    <div className="col-md-4"></div>
                  )}

                  {(user && user[0].usuario_rol) === "admin" ? (
                    <div className="col-md-4">
                      <button
                        type="button"
                        className="btn btn-sm btn-block btn-danger"
                        id={credito.credito_id + "-eliminar-credito"}
                        onClick={handleEliminarCredito(credito.credito_id)}
                      >
                        Eliminar
                      </button>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}

              {/* Mostrar las cuotas */}
              <Cuotas
                creditoCuotas={credito.cuotas}
                getCreditos={getCreditos}
              />
            </>
          }
        </div>
      </div>



    </div>
  );
};

export default Credito;
