import React, { useState } from 'react';
import { calcularCredito, convertirACOP } from '../utils';
import BootstrapSelectPicker from '../SelectPicker';

// import "theme/vendors/scripts/core.js";
// import "../..theme/vendors/scripts/script.min.js";
// import "../../theme/vendors/scripts/process.js";
// import "../../theme/vendors/scripts/layout-settings.js";
// import "../../theme/src/plugins/apexcharts/apexcharts.min.js";

const Simulador = () => {
    const [formData, setFormData] = useState({
        simuladorCreditoMonto         : '',
        simuladorCreditoNroCuotas     : '',
        simuladorCreditoModalidadPago : '',
        simuladorValorCuota           : '',
        simuladorInteres              : '',
        simuladorAval                 : '',
        simuladorTotalPagar           : '',
        mostrarSimulacion             : false
    });

    const handleChange = (evt) => {
        setFormData({
            ...formData,
            [evt.target.name]: evt.target.value
        });
    }

    const handleSubmitSimularCredito = async (evt) => {
        evt.preventDefault();

        let monto = formData.simuladorCreditoMonto;
        let nroCuotas = formData.simuladorCreditoNroCuotas;
        let modalidad = formData.simuladorCreditoModalidadPago;

        let credito = calcularCredito(monto, nroCuotas, modalidad);
        
        let totalPagar = credito[0].totalPagar;
        let valorInteres = credito[0].valorInteres;
        let valorAval = credito[0].valorAval;
        let valorCuota = credito[0].valorCuota;

        // let valorCapitalCuota = credito[0].valorCapitalCuota;
        // let valorInteresCuota = credito[0].valorInteresCuota;
        // let valorAvalCuota = credito[0].valorAvalCuota;        

        // console.log('totalPagar:', convertirACOP(totalPagar));
        // console.log('valorInteres:', convertirACOP(valorInteres));
        // console.log('valorAval:', convertirACOP(valorAval));
        // console.log('valorCuota:', convertirACOP(valorCuota));
        // console.log('valorCapitalCuota:', convertirACOP(valorCapitalCuota));
        // console.log('valorInteresCuota:', convertirACOP(valorInteresCuota));
        // console.log('valorAvalCuota:', convertirACOP(valorAvalCuota));
        

        setFormData({
            ...formData,
            simuladorValorCuota : convertirACOP(valorCuota),
            simuladorInteres    : convertirACOP(valorInteres),
            simuladorAval       : convertirACOP(valorAval),
            simuladorTotalPagar : convertirACOP(totalPagar),
            mostrarSimulacion   : formData.simuladorCreditoMonto && formData.simuladorCreditoNroCuotas && formData.simuladorCreditoModalidadPago
        });
    }

    return (
        <>
            <div className="pd-20 card-box mb-30">
                <div className="clearfix">
                    <div className="pull-left">
                        <h4 className="text-blue h4">Simulador</h4>
                        {/* <p className="mb-30">Cargar información de un cliente</p> */}
                    </div>
                </div>

                <br />

                <form onSubmit={ handleSubmitSimularCredito }>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group"> 
                                <label htmlFor="simuladorCreditoMonto">Monto del crédito</label>
                                <input 
                                    className="form-control" 
                                    id="simuladorCreditoMonto"
                                    name="simuladorCreditoMonto"
                                    type="text" 
                                    placeholder="Monto del crédito"
                                    value={ formData.simuladorCreditoMonto }
                                    onChange={ handleChange }
                                    required
                                />
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                            <div className="form-group"> 
                                <label htmlFor="simuladorCreditoNroCuotas">Número de Cuotas</label>
                                <BootstrapSelectPicker
                                    idNameSelect={'simuladorCreditoNroCuotas'}
                                    selectValue={formData.simuladorCreditoNroCuotas}
                                    handleChange={handleChange}
                                    options={[
                                        { value: '4', name: '4' },
                                        { value: '5', name: '5' },
                                        { value: '6', name: '6' },
                                        { value: '7', name: '7' },
                                        { value: '8', name: '8' },
                                    ]}
                                    required={true}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="simuladorCreditoModalidadPago">Modalidad de pago</label>
                        <BootstrapSelectPicker
                            idNameSelect={'simuladorCreditoModalidadPago'}
                            selectValue={formData.simuladorCreditoModalidadPago}
                            handleChange={handleChange}
                            options={[
                                { value: 'Semanal', name: 'Semanal' },
                                { value: 'Quincenal', name: 'Quincenal' },
                            ]}
                            required={true}
                        />
                    </div>

                    <button type="submit" className="btn btn-block btn-primary">
                        Simular
                    </button>
                </form>
            </div>

            <div 
                className="row"
                style={{ 'display': formData.mostrarSimulacion &&
                                    formData.simuladorCreditoMonto && 
                                    formData.simuladorCreditoNroCuotas && 
                                    formData.simuladorCreditoModalidadPago 
                                    ? '' : 'none'
                      }}
            >
                <div className="col-md-12 col-sm-12 mb-30">
                    <div className="pd-20 card-box height-100-p">
                        <ul className="list-group">
                            <li className="list-group-item"><b>Monto del credito: </b>{ convertirACOP(formData.simuladorCreditoMonto) }</li>
                            <li className="list-group-item"><b>Cuotas: </b>{ formData.simuladorCreditoNroCuotas }</li>
                            <li className="list-group-item"><b>Frecuencia de pago: </b>{ formData.simuladorCreditoModalidadPago }</li>
                            <li className="list-group-item"><b>Valor de la cuota: </b>{ formData.simuladorValorCuota }</li>
                            <li className="list-group-item"><b>Interés: </b>{ formData.simuladorInteres }</li>
                            <li className="list-group-item"><b>Aval: </b>{ formData.simuladorAval }</li>
                            <li className="list-group-item"><b>Total a pagar: </b>{ formData.simuladorTotalPagar }</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Simulador