// import React, {useEffect} from "react";
// import $ from "jquery"
// import "bootstrap-select/dist/css/bootstrap-select.min.css";
// import "bootstrap-select/dist/js/bootstrap-select";

export default function BootstrapSelectPicker({
    idNameSelect, selectValue, handleChange, options, required
}) {
    // const handleKeydow = (evt) => {
    //     console.log('evt:', evt);
    //     evt.preventDefault();
    //     console.log(evt.ta);
    //     // if (evt.keyCode === 13) {}
    //     console.log(evt.key);
    //  }

    // useEffect(() => {
    //     $.fn.selectpicker.Constructor.BootstrapVersion = '4';
    //     $('.selectpicker').selectpicker(); // -> add load script. Magic here
    //     $('.bootstrap-select ul.dropdown-menu').addClass("show"); // -> Fix picker dropdown don't render datah

    //     // $('.selectpicker').on('rendered.bs.select', function (e, clickedIndex, isSelected, previousValue) {
    //     //     console.log('select:', e);
    //     // });

    //     // $('#'+idNameSelect).on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
    //     //     console.log('select');
    //     // });
          
    // }, [idNameSelect]);

    return (
        <select
            // className="selectpicker form-control"
            className="form-control"
            // data-live-search="true"
            id={ idNameSelect }
            name={ idNameSelect }
            value={ selectValue }
            onChange={ handleChange }
            required={ required }
            // onKeyDown={ handleKeydow }
        >
            <option value={''}>- Selecione -</option>
            { options.map((option, index) => (
                <option 
                    key={index}
                    value={option.value}
                >
                    {option.name}
                </option>
            ))}
        </select>
    )
}