import React from 'react';
import { Outlet } from "react-router-dom"

// import layoutSettings from './theme/vendors/scripts/layout-settings.js';
// import dashboard3 from './theme/vendors/scripts/dashboard3.js';

import Preloader from './components/Layouts/Preloader.js';
import Header from './components/Layouts/Header.js';
// import RightSidebar from './components/Layouts/RightSidebar.js';
import LeftSidebar from './components/Layouts/LeftSidebar.js';
import MainContainer from './components/Layouts/MainContainer.js';
// import WelcomeModal from './components/Layouts/WelcomeModal.js';

export default function Root() {
    // useEffect(() => {
    //     // layoutSettings();
    //     // dashboard3();
    // },[]);

    const onClick = (e) => { e.preventDefault() }

    // console.log('Outlet:', Outlet);

    return (
        <>
            <Preloader />
            <Header onClick={ onClick } />
            {/* <RightSidebar onClick={ onClick } /> */}
            <LeftSidebar onClick={ onClick } />
            <MainContainer onClick={ onClick } Outlet={ Outlet}/>
            {/* <WelcomeModal /> */}
        </>
    )
}