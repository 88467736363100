const Reportes = () => {
    return (
        <div className="pd-20 card-box mb-30">
            <div className="mb-20">
                <div className="clearfix">
                    <div className="pull-left">
                        <h4 className="text-blue h4">Reportes</h4>
                        {/* <p className="mb-30">Cargar información de un cliente</p> */}
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <button
                        type="button"
                        id="btnNuevoComercio"
                        className="btn btn-block btn-outline-info"
                        data-backdrop="static"
                        data-toggle="modal"
                        data-target="#modalNuevoComercio"
                    >
                        Cuotas recaudadas
                    </button>
                </div>

                <div className="col-md-6">
                    <button
                        type="button"
                        id="btnNuevoComercio"
                        className="btn btn-block btn-outline-info"
                        data-backdrop="static"
                        data-toggle="modal"
                        data-target="#modalNuevoComercio"
                    >
                        Creditos registrados
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Reportes